import React, { useEffect, useState } from 'react';
import { getUserSupportData } from '../../api/Api';
import DefaultLayout from '../../layout/Defaultlayout';
import { FaSearch } from 'react-icons/fa';
import Pagination from '../../components/pagination/Pagination';
import Loader from '../../common/loader/Index';
import Select from 'react-select';

interface Message {
  type: 'user' | 'admin';
  message: string;
  timestamp: string;
}

interface UserSupportMessage {
  id: number;
  subject: string;
  messages: string; // JSON string
  created_at: string;
  email: string;
  name: string;
  phone: string;
  user_id: number;
}

/* Dropdown filter  */
const filterOptions = [
  { value: 'all', label: 'All' },
  { value: 'pending', label: 'Pending' },
  { value: 'received', label: 'Received' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0',
    borderColor: '#ffff',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ffff',
    },
    fontSize: '14px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#ccc' : state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transperant',
      // color: '#000',
    },
    fontSize: '14px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
    fontSize: '14px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const SupportMessage: React.FC = () => {
  const [supportMessages, setSupportMessages] = useState<UserSupportMessage[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [chatVisibility, setChatVisibility] = useState<Record<number, boolean>>(
    {},
  );
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const messagesPerPage = 7; // Display 7 messages per page

  const fetchUserSupportData = async () => {
    try {
      const response = await getUserSupportData();

      if (response?.data.success) {
        setSupportMessages(response?.data.user);
      } else {
        setError('Failed to fetch support messages');
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred while fetching support messages');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserSupportData();
  }, []);

  const toggleChatVisibility = (id: number) => {
    setChatVisibility((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // Filter messages based on search query and status
  const filteredMessages = supportMessages.filter((userMessage) => {
    const messages: Message[] = JSON.parse(userMessage.messages);
    const adminReplyExists = messages.some((msg) => msg.type === 'admin');
    const isPending = !adminReplyExists;

    const matchesSearch = userMessage.subject
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus =
      statusFilter === 'all' ||
      (statusFilter === 'pending' && isPending) ||
      (statusFilter === 'received' && !isPending);

    return matchesSearch && matchesStatus;
  });

  // Pagination logic
  const totalPages = Math.ceil(filteredMessages.length / messagesPerPage);
  const startIndex = (currentPage - 1) * messagesPerPage;
  const endIndex = Math.min(
    startIndex + messagesPerPage - 1,
    filteredMessages.length - 1,
  );
  const totalItems = filteredMessages.length;

  const paginatedMessages = filteredMessages.slice(startIndex, endIndex + 1);

  if (error) {
    return <div className="text-red-600">{error}</div>;
  }


  const customFilter = (
    option: any,
    inputValue: string
  ): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };

  return (
    <DefaultLayout title="Support Details">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container mx-auto">
            <div className="bg-white shadow-lg rounded-lg mt-10">
              <div className="flex flex-col md:flex-row justify-between items-center mb-2 mt-2 bg-[#F7F7F7] p-4 rounded-lg gap-5">
                <div className="bg-gray-200 rounded-lg">
                  <p className="font-medium text-md text-black animate-fade-in">
                    Showing {paginatedMessages.length} of {totalPages} messages
                  </p>
                </div>
                <div className="flex flex-col sm:justify-end sm:flex-row sm:items-center w-full sm:w-[70%] sm:space-x-4">
                  {/* Search Bar */}
                  <div className="relative w-full xl:w-[50%] sm:w-full mb-4 sm:mb-0">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="border border-stroke rounded-xl pr-11 px-3 py-2 w-full bg-white text-gray-800 focus:outline-none"
                    />
                    <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                  </div>

                  {/* Dropdown for filter options */}
                  <div className="relative w-full sm:w-1/3 xl:w-[20%]">
                    <Select
                      options={filterOptions}
                      styles={customStyles}
                      placeholder="Actions"
                      className="border border-stroke rounded-xl"
                      onMenuOpen={() => setIsOpen(true)}
                      onMenuClose={() => setIsOpen(false)}
                      value={filterOptions.find(
                        (option) => option.value === statusFilter,
                      )}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setStatusFilter(selectedOption.value);
                        }
                      }}
                      filterOption={customFilter}

                    />
                    <span
                      className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                        isOpen ? 'rotate-180' : 'rotate-0'
                      } `}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>

              <div className="overflow-x-auto animate-fade-in scrollbar-custom rounded-lg shadow-md">
                <table className="min-w-full  rounded-lg ">
                  <thead>
                    <tr className="hover:bg-gray-50 transition duration-200">
                      <th
                        scope="col"
                        className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                      >
                        No.
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                      >
                        Subject
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                      >
                        Applied On
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 text-center border-b border-gray whitespace-nowrap"
                      >
                        View More
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedMessages?.length > 0 ? (
                      paginatedMessages?.map((userMessage, index) => {
                        const messages: Message[] = JSON.parse(
                          userMessage.messages,
                        );
                        const adminReplyExists = messages.some(
                          (msg) => msg.type === 'admin',
                        );
                        const isChatVisible = chatVisibility[userMessage.id];
                        return (
                          <>
                            <tr
                              key={index}
                              className="hover:bg-gray-50 transition duration-200"
                            >
                              <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                                {(currentPage - 1) * messagesPerPage +
                                  index +
                                  1}
                              </td>
                              <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                                {userMessage.subject}
                              </td>
                              <td
                                className={`py-3 px-6 border-b font-semibold  border-gray whitespace-nowrap ${adminReplyExists ? 'text-green-600' : 'text-yellow-600'}`}
                              >
                                {adminReplyExists ? 'Received' : 'Pending'}
                              </td>
                              <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                                {new Date(
                                  userMessage.created_at,
                                ).toLocaleString('en-GB', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                })}
                              </td>
                              <td
                                className={`flex justify-center py-3 px-6 whitespace-nowrap border-b border-gray `}
                              >
                                {/* <button
                                  onClick={() =>
                                    toggleChatVisibility(userMessage.id)
                                  }
                                  className="bg-[#594255] text-white px-4 py-2 rounded-md"
                                >
                                  {isChatVisible ? 'Hide Chat' : 'Show Chat'}
                                </button> */}
                                <button
                                  className={`text-white bg-[#F7F7F7] border border-gray rounded-lg px-2 py-2 text-center inline-flex items-center  ${isChatVisible ? 'transform rotate-180' : ''}`}
                                  onClick={() =>
                                    toggleChatVisibility(userMessage.id)
                                  }
                                  // disabled={
                                  //   loadingSubscription || user?.status === null
                                  // }
                                >
                                  <svg
                                    className="w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                            {isChatVisible && (
                              <tr>
                                <td colSpan={5} className="px-6 py-4">
                                  {messages.map((msg, index) => (
                                    <div
                                      key={index}
                                      className={`mt-1 p-2 rounded ${msg.type === 'admin' ? 'bg-green-50' : 'bg-blue-50'}`}
                                    >
                                      <span
                                        className={`font-bold ${msg.type === 'admin' ? 'text-green-600' : 'text-blue-600'}`}
                                      >
                                        {msg.type === 'user'
                                          ? 'You:'
                                          : 'Admin:'}
                                      </span>
                                      <p className="text-gray-800">
                                        {msg.message}
                                      </p>
                                      <span className="text-xs text-gray-400">
                                        {new Date(msg.timestamp).toLocaleString(
                                          'en-GB',
                                          {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                          },
                                        )}
                                      </span>
                                    </div>
                                  ))}
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="py-4 text-center">
                          No data found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {supportMessages?.length > 4 && (
                  <div className="mt-4">
                    <Pagination
                      totalPages={totalPages}
                      setCurrentPage={setCurrentPage}
                      startIndex={startIndex}
                      endIndex={endIndex}
                      totalItems={totalItems}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default SupportMessage;
