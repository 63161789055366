import { useEffect, useState } from 'react';
import { getUserActivity, guideTourComplete } from '../../api/Api';
import DefaultLayout from '../../layout/Defaultlayout';
import Loader from '../../common/loader/Index';
import moment from 'moment';
import { CiCalendarDate } from 'react-icons/ci';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select, { StylesConfig } from 'react-select';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useTour } from '../../Authcontext';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/Userslice';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface ActivitiesProps {
  id: number;
  user_id: number;
  action_type: string;
  description: string;
  created_at: string;
}

const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0 10px',
    borderColor: '#D8D8D8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any; isDisabled: boolean }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#A9A9A9' : state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: state.isDisabled ? '#fff' : '#DFC749',
      color: state.isDisabled ? '#A9A9A9' : '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Scrollbar thumb hover color
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const option = [
  // { value: 'all', label: 'All' },
  // { value: 'today', label: 'Today' },
  // { value: 'yesterday', label: 'Yesterday' },
  // { value: 'this week', label: 'This week' },
  // { value: 'last week', label: 'Last week' },
  // { value: 'this month', label: 'This month' },
  // { value: 'last month', label: 'Last month' },
  // { value: 'custom', label: 'Custom' },
  { value: 'day', label: 'Day' },
  { value: 'week (sun-sat)', label: 'Week (sun-sat)' },
  { value: 'week (mon-sun)', label: 'Week (mon-sun)' },
  { value: 'month', label: 'Month' },
  { value: 'custom', label: 'Custom' },
];

const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const addMonths = (date: Date, months: number) => {
  const result = new Date(date);
  const targetMonth = result.getMonth() + months;
  result.setMonth(targetMonth);

  // If the result's month is incorrect due to rollover, adjust the date
  if (result.getMonth() !== ((targetMonth % 12) + 12) % 12) {
    result.setDate(0); // Set to the last day of the previous month
  }

  return result;
};

const getStartOfWeek = (date: Date, startDay: 'sunday' | 'monday') => {
  const day = startDay === 'sunday' ? 0 : 1;
  const diff =
    date.getDate() -
    date.getDay() +
    (date.getDay() === 0 && day === 1 ? -6 : day);
  return new Date(date.setDate(diff));
};

const getEndOfWeek = (date: Date, endDay: 'saturday' | 'sunday') => {
  const start = getStartOfWeek(
    date,
    endDay === 'saturday' ? 'sunday' : 'monday',
  );
  return addDays(start, 6);
};

const getStartOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getEndOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

function Myactivity() {
  const navigate = useNavigate();
  const [activities, setActivities] = useState<ActivitiesProps[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<
    ActivitiesProps[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('custom');
  const [startDates, setStartDates] = useState(getStartOfMonth(new Date()));
  const [endDates, setEndDates] = useState(new Date());
  const userDetails = useSelector(selectUser);
  const userData = userDetails?.user;

  useEffect(() => {
    GetActivities();
  }, []);

  // Filter activities based on date range
  useEffect(() => {
    filterActivities();
  }, [activities, startDates, endDates]);

  // useEffect(() => {
  //   filterActivities();
  // }, [activities, startDates, endDates, selectedOption]);

  const GetActivities = async () => {
    try {
      setLoading(true);
      const response = await getUserActivity();
      if (response?.data.success) {
        setActivities(response.data.activities);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching activities:', error);
      setLoading(false);
    }
  };

  /* =================== Date range filter =================== */
  const handleDropdownChange = (selected: any) => {
    setSelectedOption(selected.value);

    // Adjust dates based on selection
    const today = new Date();
    switch (selected.value) {
      case 'week (sun-sat)':
        setStartDates(getStartOfWeek(today, 'sunday'));
        setEndDates(getEndOfWeek(today, 'saturday'));
        break;
      case 'week (mon-sun)':
        setStartDates(getStartOfWeek(today, 'monday'));
        setEndDates(getEndOfWeek(today, 'sunday'));
        break;
      case 'month':
        setStartDates(getStartOfMonth(today));
        setEndDates(getEndOfMonth(today));
        break;
      case 'day':
        setStartDates(today);
        setEndDates(today);
        break;
      case 'custom':
        setStartDates(getStartOfMonth(today));
        setEndDates(today);
        break;
      default:
        break;
    }
  };

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDates(start);
    setEndDates(end);
  };

  const onDayChange = (date: any) => {
    setStartDates(date);
    setEndDates(date);
  };

  const handleNavigation = (direction: 'prev' | 'next') => {
    const multiplier = direction === 'prev' ? -1 : 1;

    if (selectedOption.includes('week')) {
      const delta = multiplier * 7;
      setStartDates(addDays(startDates, delta));
      setEndDates(addDays(endDates, delta));
    } else if (selectedOption === 'month') {
      const newStart = addMonths(startDates, multiplier);
      const newEnd = addMonths(endDates, multiplier);
      setStartDates(getStartOfMonth(newStart));
      setEndDates(getEndOfMonth(newEnd));
    }
  };
  const filterActivities = () => {
    if (selectedOption === 'all') {
      setFilteredActivities(activities);
    } else if (startDates && endDates) {
      const filtered = activities.filter((activity) => {
        const createdAt = new Date(activity.created_at);
        const startDate = new Date(startDates);
        const endDate = new Date(endDates);
        // Normalize dates to start of the day
        const normalizeDate = (date: Date) => {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        };
        const createdAtNormalized = normalizeDate(createdAt);
        const startDateNormalized = normalizeDate(startDate);
        const endDateNormalized = normalizeDate(endDate);

        return (
          createdAtNormalized >= startDateNormalized &&
          createdAtNormalized <= endDateNormalized
        );
      });
      setFilteredActivities(filtered);
    }
  };

  const formatDate = (dateString: string) => {
    const date = moment(dateString);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (date.isSame(today, 'day')) {
      return `Today, ${date.format('h:mm A')}`;
    } else if (date.isSame(yesterday, 'day')) {
      return `Yesterday, ${date.format('h:mm A')}`;
    } else {
      return date.format('MMM DD, h:mm A');
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // const handleDropdownChange = (selected: any) => {
  //   setSelectedOption(selected.value);
  //   let startDate = new Date();
  //   let endDate = new Date();

  //   switch (selected.value) {
  //     case 'today':
  //       startDate = new Date();
  //       endDate = new Date();
  //       break;
  //     case 'yesterday':
  //       startDate = subDays(new Date(), 1);
  //       endDate = subDays(new Date(), 1);
  //       break;
  //     case 'this week':
  //       startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  //       endDate = endOfWeek(new Date(), { weekStartsOn: 1 });
  //       break;
  //     case 'last week':
  //       startDate = subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7);
  //       endDate = subDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 7);
  //       break;
  //     case 'this month':
  //       startDate = startOfMonth(new Date());
  //       endDate = endOfMonth(new Date());
  //       break;
  //     case 'last month':
  //       startDate = startOfMonth(subDays(new Date(), 30));
  //       endDate = endOfMonth(subDays(new Date(), 30));
  //       break;
  //     default:
  //       break;
  //   }
  //   setStartDates(startDate);
  //   setEndDates(endDate);
  // };
  // const onChange = (dates: any) => {
  //   const [start, end] = dates;
  //   setStartDates(start);
  //   setEndDates(end);
  // };

  //  ************* website tour *************
  const { tourStep, setTourStep } = useTour();

  useEffect(() => {
    setTimeout(() => {
      // Check if the tour has already been completed
      if (userData?.tour_guide == 0 && tourStep === 6) {
        startTour();
      }
    }, 300);
  }, [tourStep, activities]);

  // Start the tour for Page 1
  const startTour = () => {
    const tour = driver({
      showProgress: true,
      doneBtnText: 'Next →',
      steps: [
        {
          element: '#my-activity',
          popover: {
            title: 'Activities',
            description: 'Your activities will appear here.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#filter-activity',
          popover: {
            title: 'Filter',
            description: 'You can date wise filter you activities.',
            side: 'bottom',
            align: 'end',
          },
        },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#filter-activity') {
          onCompleteTour();
        } else {
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
    });

    tour.drive();
  };

  // Navigate to the next page and update the step
  const onCompleteTour = () => {
    setTourStep(7);
    navigate('/settings');
  };
  const customFilter = (option: any, inputValue: string): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };

  return (
    <>
      <DefaultLayout title="My Activity">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div id="my-activity" className="bg-white w-full rounded-lg">
              <div className="border-b border-[#e4e8eb] p-4 flex flex-col md:flex-row gap-4 justify-between">
                <div className="w-[40%] md:w-full">
                  <p className="text-black font-bold text-xl">Activity</p>
                </div>
                {/* <div className="flex flex-row justify-end gap-2 w-full">
                  {selectedOption === 'custom' && (
                    <>
                      <div className="mr-5 flex items-center">
                        <div className="border-l border-y border-[#D8D8D8] px-3 py-[8.5px] rounded-l-[11px]">
                          <CiCalendarDate
                            style={{
                              color: '#000',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </div>
                        <DatePicker
                          selected={startDates}
                          onChange={onChange}
                          startDate={startDates}
                          endDate={endDates}
                          selectsRange
                          dateFormat="dd/MM/yyyy"
                          className="border border-[#D8D8D8] w-[115%] sm:w-[111%] text-black px-3 py-[6.5px] rounded-r-[11px]"
                          maxDate={new Date()}
                        />
                      </div>
                    </>
                  )}
                  <div
                    id="filter-activity"
                    data-tooltip-id="filter-activity"
                    className="relative w-[53%] sm:w-[40%] md:w-[30%] lg:w-[20%]"
                  >
                    <Select
                      options={option}
                      styles={customStyles}
                      onChange={handleDropdownChange}
                      value={option.find(
                        (option) => option.value === selectedOption,
                      )}
                      placeholder="Select"
                      onMenuOpen={() => setIsOpen(true)}
                      onMenuClose={() => setIsOpen(false)}
                      filterOption={customFilter}
                    />
                    <span
                      className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                        isOpen ? 'rotate-180' : 'rotate-0'
                      } `}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                      </svg>
                    </span>
                  </div>
                </div> */}
                <div className="flex flex-col sm:flex-row w-full gap-2">
                  <div
                    data-tooltip-id="filter"
                    className="relative w-[40%] sm:w-[50%] xl:w-[60%]"
                  >
                    <Select
                      options={option}
                      styles={customStyles}
                      className="border border-stroke focus:outline-none rounded-xl"
                      placeholder="Select"
                      onMenuOpen={() => setIsOpen(true)}
                      onMenuClose={() => setIsOpen(false)}
                      onChange={handleDropdownChange}
                      value={option.find(
                        (option) => option.value === selectedOption,
                      )}
                      filterOption={customFilter}
                      id="card-engagement-filter"
                    />
                    <span
                      className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                        isOpen ? 'rotate-180' : 'rotate-0'
                      } `}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                      </svg>
                    </span>
                  </div>
                  <ReactTooltip id="filter" content="Filter" />
                  {selectedOption !== 'custom' && selectedOption !== 'day' && (
                    <div className="flex gap-2 items-center w-full">
                      <button onClick={() => handleNavigation('prev')}>
                        <IoIosArrowBack />
                      </button>
                      <p className="font-bold text-sm">
                        {startDates.toDateString()} to {endDates.toDateString()}
                      </p>
                      <button onClick={() => handleNavigation('next')}>
                        <IoIosArrowForward />
                      </button>
                    </div>
                  )}
                  {selectedOption == 'day' && (
                    <div className="flex items-center w-full">
                      <div className="border-l border-y border-[#D8D8D8] px-3 py-[8.5px] rounded-l-[11px]">
                        <CiCalendarDate
                          style={{
                            color: '#000',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </div>
                      <DatePicker
                        selected={startDates}
                        onChange={onDayChange}
                        dateFormat="dd/MM/yyyy"
                        className="border border-[#D8D8D8] text-black px-3 py-[6.5px] rounded-r-[11px]"
                        maxDate={new Date()}
                      />
                    </div>
                  )}
                  {selectedOption == 'custom' && (
                    <div className="flex items-center w-full">
                      <div className="border-l border-y border-[#D8D8D8] px-3 py-[8.5px] rounded-l-[11px]">
                        <CiCalendarDate
                          style={{
                            color: '#000',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </div>
                      <DatePicker
                        selected={startDates}
                        onChange={onChange}
                        startDate={startDates}
                        endDate={endDates}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        className="border border-[#D8D8D8] w-[115%] sm:w-[111%] text-black px-3 py-[6.5px] rounded-r-[11px]"
                        maxDate={new Date()}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="p-4 max-h-[32rem] overflow-y-auto scrollbar-custom scroll-smooth">
                {filteredActivities?.length > 0 ? (
                  <ol className="activity-feed p-3.5 list-none">
                    {filteredActivities?.map((activity) => (
                      <li
                        key={activity.id}
                        className="activity-feed-item relative pb-5 pl-7.5 border-l-2 border-solid border-[#e4e8eb] text-purpletacx font-medium"
                      >
                        <span className="relative -top-2">
                          {capitalizeFirstLetter(activity.description)}
                        </span>
                        <time className="block relative -top-[8px] text-[#8c96a3] text-sm ">
                          {/* {moment(activity.created_at).format('MMM DD')} */}
                          {formatDate(activity.created_at)}
                        </time>
                      </li>
                    ))}
                  </ol>
                ) : (
                  <p className="p-4">No activities to show.</p>
                )}
              </div>
            </div>
          </>
        )}
        <ReactTooltip id="filter-activity" place="left" content="Filter" />
      </DefaultLayout>
    </>
  );
}

export default Myactivity;
