import React, { useState, useEffect } from 'react';
import DefaultLayout from '../layout/Defaultlayout';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import {
  createCard,
  deleteCard,
  generateText,
  getAllCards,
  guideTourComplete,
  ProfileData,
} from '../api/Api';
import QrCodeModel from '../components/model/Qrcodemodel';
import MyIcon from '../components/cardeditor/icons/Myicon';
import Loader from '../common/loader/Index';
import PreviewCard from '../components/cardeditor/Previewcard';
import { useAuth } from '../Authcontext';
import PromptModal from '../components/model/Textgeneratermodel';
import { PushInAppNotification } from '../api/NotificationsApi';
import { useSelector } from 'react-redux';
import { selectUser } from '../store/slice/Userslice';
import { useTour } from '../Authcontext';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

interface Card {
  selected_theme: string;
  id: string;
  card_type: string;
  global_cardStyle: string;
  items: string;
  user_id: number;
}

interface GlobalStyling {
  textColor?: any;
  fontColor?: any;
  linkColor?: any;
  fontSize?: string;
  status?: boolean;
  groundColor?: string;
  ignoreFieldArray?: any;
  globalChangesList?: any;
  buttonPadding: number;
  buttonMargin: number;
  buttonRadius: number;
  pmColor: string;
  seColor: string;
}

interface CustomizationOptions {
  backgroundImage?: { default: string };
  fname?: { default: string };
  lname?: { default: string };
  companyLogo?: { default: string };
  profileImage?: { default: string };
  jobTitles?: { default: string[] };
  companyName?: { default: string };
  hideCompanyLogo?: { default: boolean };
  nameFontSize?: any;
  companyNameFontSize?: any;
  jobTitleFontSize?: any;
  fontColor?: any;
  linkColor?: any;
  companyNameTextColor?: { default: string };
  globalStyling?: GlobalStyling;
  [key: string]: any; // Allow for additional properties
}

interface Item {
  componentName: string;
  id: string;
  customizationOptions: CustomizationOptions;
  boxCount: number;
  boxData: any;
}

interface ParsedCard {
  selected_theme: string;
  id: number;
  card_type: string;
  global_cardStyle: {
    backgroundColor: string;
    font: string;
  };
  items: Item[];
  user_id: number;
  card_name: string;
  card_description: string;
}

const MyCard = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState<boolean>(false);
  const [cardUrl, setCardUrl] = useState<string>('');
  const [cards, setCards] = useState<ParsedCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cardName, setCardName] = useState<string>('');
  const [cardDescription, setCardDescription] = useState<string>('');
  const [cardModal, setCardModal] = useState(false);
  const [cardError, setCardError] = useState('');
  const [deleteCardErrorModal, setdeleteCardErrorModal] = useState(false);
  const [handleNewCardLoading, setHandleNewCardLoading] = useState(false);
  const [promptModelOpen, setPromptModelOpen] = useState(false);
  const [promptVal, setPromptVal] = useState('');
  const [promptError, setPromptError] = useState('');
  const [generating, setGenerating] = useState(false);
  const selectUserDetails = useSelector(selectUser);

  // ************ profile component ( for creating basic card when user creating new card ) ***********
  const profileComponent: any = () => ({
    type: 'profile',
    componentName: 'Profile',
    customizationOptions: {
      companyLogo: {
        label: 'Company Logo',
        type: 'image',
        default: '1718108707903-SyndellLogo.png',
        tab: 'content',
      },
      hideCompanyLogo: {
        label: 'Hide Company Logo',
        type: 'boolean',
        default: false,
        tab: 'content',
      },
      backgroundImage: {
        label: 'Background Image',
        type: 'image',
        default: '1718108457839-samplebg.jpg', // Default background image
        tab: 'content',
      },
      fname: {
        label: 'First Name',
        type: 'text',
        default: 'fname',
        tab: 'content',
      },
      lname: {
        label: 'Last Name',
        type: 'text',
        default: 'lname',
        tab: 'content',
      },
      profileImage: {
        label: 'Profile Image',
        type: 'image',
        default: 'profile.jpg',
        tab: 'content',
      },
      companyName: {
        label: 'Company Name',
        type: 'text',
        default: 'Company Name',
        tab: 'content',
      },
      companyNameTextColor: {
        label: 'Company Name Text Color',
        type: 'color',
        default: '#ca8a04',
        tab: 'style',
      },
      jobTitles: {
        label: 'Job Title',
        type: 'dynamicArray',
        default: [],
        tab: 'content',
      },
      fontColor: {
        label: 'Text Color',
        type: 'color',
        default: '#000000',
        tab: 'style',
      },
      linkColor: {
        label: 'QR Text Color',
        type: 'color',
        default: '#000000',
        tab: 'style',
      },
      globalStyling: {
        fontColor: '#000000',
        linkColor: '#000000',
        fontSize: 'text-xl',
        status: false,
        ignoreFieldArray: [],
        globalChangesList: [],
      },
      typography: {
        type: 'editor',
        family: {
          label: 'Font Family',
          type: 'select',
          default: 'poppins, font-poppins',
          options: [
            {
              key: 1,
              value: 'Abril Fatface , font-abrilfatface',
              label: 'Abril Fatface',
            },
            { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
            { key: 3, value: 'monospace, font-mono', label: 'Mono' },
            { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
            { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
            { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
            { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
            { key: 8, value: 'serif, font-serif', label: 'Serif' },
          ],
          tab: 'style',
        },
        nameFontSize: {
          label: 'Size',
          type: 'select',
          default: 'text-base',
          options: [
            { value: '1px', label: '1px' },
            { value: '2px', label: '2px' },
            { value: '4px', label: '4px' },
            { value: '6px', label: '6px' },
            { value: '8px', label: '8px' },
            { value: '10px', label: '10px' },
            { value: '14px', label: '14px' },
            { value: '16px', label: '16px' },
            { value: '18px', label: '18px' },
            { value: '20px', label: '20px' },
            { value: '24px', label: '24px' },
            { value: '30px', label: '30px' },
          ],
          tab: 'style',
        },
        typographyWeight: {
          label: 'Font Weight',
          type: 'select',
          default: 'font-[600]',
          options: [
            { value: 'font-[200]', label: '200 (Extra Light)' },
            { value: 'font-[300]', label: '300 (Light)' },
            { value: 'font-[400]', label: '400 (Normal)' },
            { value: 'font-[500]', label: '500 (Medium (Recommended))' },
            { value: 'font-[600]', label: '600 (Semi Bold)' },
            { value: 'font-[700]', label: '700 (Bold)' },
            { value: 'font-[800]', label: '800 (Extra Bold)' },
          ],
          tab: 'style',
        },
        typographyStyle: {
          label: 'Style',
          type: 'select',
          default: 'normal',
          options: [
            { value: 'italic', label: 'Italic' },
            { value: 'normal', label: 'Normal' },
            { value: 'oblique', label: 'Oblique' },
          ],
          tab: 'style',
        },
        lineHeight: {
          label: 'Line Height',
          type: 'widthOption',
          default: '2',
          tab: 'content',
        },
      },
      companyTypography: {
        type: 'editor',
        family: {
          label: 'Font Family',
          type: 'select',
          default: 'poppins, font-poppins',
          options: [
            {
              key: 1,
              value: 'Abril Fatface , font-abrilfatface',
              label: 'Abril Fatface',
            },
            { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
            { key: 3, value: 'monospace, font-mono', label: 'Mono' },
            { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
            { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
            { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
            { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
            { key: 8, value: 'serif, font-serif', label: 'Serif' },
          ],
          tab: 'style',
        },
        nameFontSize: {
          label: 'Size',
          type: 'select',
          default: 'text-base',
          options: [
            { value: '1px', label: '1px' },
            { value: '2px', label: '2px' },
            { value: '4px', label: '4px' },
            { value: '6px', label: '6px' },
            { value: '8px', label: '8px' },
            { value: '10px', label: '10px' },
            { value: '14px', label: '14px' },
            { value: '16px', label: '16px' },
            { value: '18px', label: '18px' },
            { value: '20px', label: '20px' },
            { value: '24px', label: '24px' },
            { value: '30px', label: '30px' },
          ],
          tab: 'style',
        },
        companyTypographyWeight: {
          label: 'Font Weight',
          type: 'select',
          default: 'font-[600]',
          options: [
            { value: 'font-[200]', label: '200 (Extra Light)' },
            { value: 'font-[300]', label: '300 (Light)' },
            { value: 'font-[400]', label: '400 (Normal)' },
            { value: 'font-[500]', label: '500 (Medium (Recommended))' },
            { value: 'font-[600]', label: '600 (Semi Bold)' },
            { value: 'font-[700]', label: '700 (Bold)' },
            { value: 'font-[800]', label: '800 (Extra Bold)' },
          ],
          tab: 'style',
        },
        companyTypographyStyle: {
          label: 'Style',
          type: 'select',
          default: 'normal',
          options: [
            { value: 'italic', label: 'Italic' },
            { value: 'normal', label: 'Normal' },
            { value: 'oblique', label: 'Oblique' },
          ],
          tab: 'style',
        },
        lineHeight: {
          label: 'Line Height',
          type: 'widthOption',
          default: '1',
          tab: 'content',
        },
      },
      jobTypography: {
        type: 'editor',
        family: {
          label: 'Font Family',
          type: 'select',
          default: 'poppins, font-poppins',
          options: [
            {
              value: 'Abril Fatface , font-abrilfatface',
              label: 'Abril Fatface',
            },
            { value: 'Cinzel , font-cinzel', label: 'Cinzel' },
            { value: 'monospace, font-mono', label: 'Mono' },
            { value: 'poppins, font-poppins', label: 'Poppins' },
            { value: 'sans-serif, font-sans', label: 'Sans' },
            { value: 'Satisfy , font-satisfy', label: 'Satisfy' },
            { value: 'Satoshi, font-satoshi', label: 'Satoshi' },
            { value: 'serif, font-serif', label: 'Serif' },
          ],
          tab: 'style',
        },
        nameFontSize: {
          label: 'Size',
          type: 'select',
          default: 'text-base',
          options: [
            { value: '1px', label: '1px' },
            { value: '2px', label: '2px' },
            { value: '4px', label: '4px' },
            { value: '6px', label: '6px' },
            { value: '8px', label: '8px' },
            { value: '10px', label: '10px' },
            { value: '14px', label: '14px' },
            { value: '16px', label: '16px' },
            { value: '18px', label: '18px' },
            { value: '20px', label: '20px' },
            { value: '24px', label: '24px' },
            { value: '30px', label: '30px' },
          ],
          tab: 'style',
        },
        jobTypographyWeight: {
          label: 'Font Weight',
          type: 'select',
          default: 'font-[600]',
          options: [
            { value: 'font-[200]', label: '200 (Extra Light)' },
            { value: 'font-[300]', label: '300 (Light)' },
            { value: 'font-[400]', label: '400 (Normal)' },
            { value: 'font-[500]', label: '500 (Medium (Recommended))' },
            { value: 'font-[600]', label: '600 (Semi Bold)' },
            { value: 'font-[700]', label: '700 (Bold)' },
            { value: 'font-[800]', label: '800 (Extra Bold)' },
          ],
          tab: 'style',
        },
        jobTypographyStyle: {
          label: 'Style',
          type: 'select',
          default: 'normal',
          options: [
            { value: 'italic', label: 'Italic' },
            { value: 'normal', label: 'Normal' },
            { value: 'oblique', label: 'Oblique' },
          ],
          tab: 'style',
        },
        lineHeight: {
          label: 'Line Height',
          type: 'widthOption',
          default: '1',
          tab: 'content',
        },
      },
    },
    divider: [
      {
        label: 'Typography',
        value: [
          'backgroundImage',
          'fname',
          'lname',
          'profileImage',
          'fontColor',
          'linkColor',
          'typography',
        ],
      },
      {
        label: 'Company',
        value: [
          'companyLogo',
          'hideCompanyLogo',
          'companyName',
          'companyNameFontSize',
          'companyNameTextColor',
          'companyTypography',
        ],
      },
      {
        label: 'Job',
        value: ['jobTitles', 'jobTitleFontSize', 'jobTypography'],
      },
    ],

    displayType: 'block',
    id: `profile-master-1`,
  });

  const formComponent: any = () => ({
    type: 'form',
    componentName: 'Form',
    displayType: 'block',
    customizationOptions: {
      fields: {
        label: 'Form Fields',
        type: 'formFields',
        default: [
          {
            id: '1',
            label: 'Name',
            type: 'text',
            placeholder: 'Name',
            required: false,
          },
          {
            id: '2',
            label: 'Email',
            type: 'email',
            placeholder: 'Email',
            required: true,
          },
          {
            id: '3',
            label: 'Message',
            type: 'textarea',
            placeholder: 'Message',
            required: false,
          },
        ],
        tab: 'content',
      },
      labelShow: {
        label: 'Label',
        type: 'boolean',
        default: true,
        tab: 'content',
      },
      buttonSize: {
        label: 'Size',
        type: 'select',
        default: 'px-4 py-2',
        options: [
          { value: 'px-2 py-0', label: 'Extra small' },
          { value: 'px-4 py-2', label: 'Small' },
          { value: 'px-6 py-4', label: 'Medium' },
          { value: 'px-8 py-6', label: 'Large' },
        ],
        tab: 'content',
      },
      buttonWidth: {
        label: 'Column width',
        type: 'select',
        default: '100',
        options: [
          { value: '30', label: '30%' },
          { value: '40', label: '40%' },
          { value: '50', label: '50%' },
          { value: '60', label: '60%' },
          { value: '70', label: '70%' },
          { value: '80', label: '80%' },
          { value: '90', label: '90%' },
          { value: '100', label: '100%' },
        ],
        tab: 'content',
      },
      buttonHr: {
        type: 'hrTag',
        tab: 'content',
      },
      buttonLabel: {
        label: 'Submit',
        type: 'text',
        default: 'Submit',
        tab: 'content',
      },
      icon: {
        label: 'Icon',
        type: 'icon',
        default: [
          {
            name: 'icon',
            iconType: 'local',
            iconName: 'iconoir:submit-document',
            fgColor: '#000000',
            show: 'none',
          },
        ],
        tab: 'content',
      },
      iconPosition: {
        label: 'Icon Position',
        type: 'alignment',
        default: 'flex-row',
        options: [
          { value: 'flex-row', label: 'Left', icon: 'buttonAlignLeft' },
          {
            value: 'flex-row-reverse',
            label: 'Right',
            icon: 'buttonAlignRight',
          },
        ],
        tab: 'content',
      },
      iconSpacing: {
        label: 'Icon Spacing',
        type: 'widthOption',
        default: '0',
        max: '100',
        min: '0',
        tab: 'content',
      },
      rowGap: {
        label: 'Rows Gap',
        type: 'widthOption',
        default: '0',
        max: '100',
        min: '0',
        tab: 'style',
      },
      labelHr: {
        type: 'hrTag',
        tab: 'style',
      },
      labelHeading: {
        label: 'Label',
        type: 'heading',
        tab: 'style',
      },
      labelSpacing: {
        label: 'Spacing',
        type: 'widthOption',
        default: '0',
        max: '100',
        min: '0',
        tab: 'style',
      },
      labelTextColor: {
        label: 'Text Color',
        type: 'color',
        default: '#594255',
        tab: 'style',
      },
      labelTextTypography: {
        type: 'editor',
        family: {
          label: 'Font Family',
          type: 'select',
          default: 'poppins, font-poppins',
          options: [
            {
              key: 1,
              value: 'Abril Fatface , font-abrilfatface',
              label: 'Abril Fatface',
            },
            { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
            { key: 3, value: 'monospace, font-mono', label: 'Mono' },
            { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
            { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
            { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
            { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
            { key: 8, value: 'serif, font-serif', label: 'Serif' },
          ],
          tab: 'style',
        },
        nameFontSize: {
          label: 'Size',
          type: 'select',
          default: '16px',
          options: [
            { value: '1px', label: '1px' },
            { value: '2px', label: '2px' },
            { value: '4px', label: '4px' },
            { value: '6px', label: '6px' },
            { value: '8px', label: '8px' },
            { value: '10px', label: '10px' },
            { value: '14px', label: '14px' },
            { value: '16px', label: '16px' },
            { value: '18px', label: '18px' },
            { value: '20px', label: '20px' },
            { value: '24px', label: '24px' },
            { value: '30px', label: '30px' },
          ],
          tab: 'style',
        },
        typographyWeight: {
          label: 'Font Weight',
          type: 'select',
          default: 'font-[400]',
          options: [
            { value: 'font-[200]', label: '200 (Extra Light)' },
            { value: 'font-[300]', label: '300 (Light)' },
            { value: 'font-[400]', label: '400 (Normal)' },
            { value: 'font-[500]', label: '500 (Medium (Recommended))' },
            { value: 'font-[600]', label: '600 (Semi Bold)' },
            { value: 'font-[700]', label: '700 (Bold)' },
            { value: 'font-[800]', label: '800 (Extra Bold)' },
          ],
          tab: 'style',
        },
        typographyStyle: {
          label: 'Style',
          type: 'select',
          default: 'normal',
          options: [
            { value: 'italic', label: 'Italic' },
            { value: 'normal', label: 'Normal' },
            { value: 'oblique', label: 'Oblique' },
          ],
          tab: 'style',
        },
        lineHeight: {
          label: 'Line Height',
          type: 'widthOption',
          default: '2',
          max: '100',
          min: '0',
          tab: 'content',
        },
      },
      fielsHr: {
        type: 'hrTag',
        tab: 'style',
      },
      fielsHeading: {
        label: 'Field',
        type: 'heading',
        tab: 'style',
      },
      fieldTextColor: {
        label: 'Text Color',
        type: 'color',
        default: '#594255',
        tab: 'style',
      },
      fieldTextTypography: {
        type: 'editor',
        family: {
          label: 'Font Family',
          type: 'select',
          default: 'poppins, font-poppins',
          options: [
            {
              key: 1,
              value: 'Abril Fatface , font-abrilfatface',
              label: 'Abril Fatface',
            },
            { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
            { key: 3, value: 'monospace, font-mono', label: 'Mono' },
            { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
            { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
            { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
            { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
            { key: 8, value: 'serif, font-serif', label: 'Serif' },
          ],
          tab: 'style',
        },
        nameFontSize: {
          label: 'Size',
          type: 'select',
          default: '16px',
          options: [
            { value: '1px', label: '1px' },
            { value: '2px', label: '2px' },
            { value: '4px', label: '4px' },
            { value: '6px', label: '6px' },
            { value: '8px', label: '8px' },
            { value: '10px', label: '10px' },
            { value: '14px', label: '14px' },
            { value: '16px', label: '16px' },
            { value: '18px', label: '18px' },
            { value: '20px', label: '20px' },
            { value: '24px', label: '24px' },
            { value: '30px', label: '30px' },
          ],
          tab: 'style',
        },
        typographyWeight: {
          label: 'Font Weight',
          type: 'select',
          default: 'font-[400]',
          options: [
            { value: 'font-[200]', label: '200 (Extra Light)' },
            { value: 'font-[300]', label: '300 (Light)' },
            { value: 'font-[400]', label: '400 (Normal)' },
            { value: 'font-[500]', label: '500 (Medium (Recommended))' },
            { value: 'font-[600]', label: '600 (Semi Bold)' },
            { value: 'font-[700]', label: '700 (Bold)' },
            { value: 'font-[800]', label: '800 (Extra Bold)' },
          ],
          tab: 'style',
        },
        typographyStyle: {
          label: 'Style',
          type: 'select',
          default: 'normal',
          options: [
            { value: 'italic', label: 'Italic' },
            { value: 'normal', label: 'Normal' },
            { value: 'oblique', label: 'Oblique' },
          ],
          tab: 'style',
        },
        lineHeight: {
          label: 'Line Height',
          type: 'widthOption',
          default: '2',
          max: '100',
          min: '0',
          tab: 'content',
        },
      },
      buttonPosition: {
        label: 'Position',
        type: 'alignment',
        default: 'justify-center',
        options: [
          { value: 'justify-start', label: 'Left', icon: 'buttonAlignLeft' },
          {
            value: 'justify-center',
            label: 'center',
            icon: 'buttonAlignCenter',
          },
          { value: 'justify-end', label: 'Right', icon: 'buttonAlignRight' },
        ],
        tab: 'style',
      },
      buttonAlignmenrt: {
        label: 'Alignment',
        type: 'alignment',
        default: 'justify-center',
        options: [
          { value: 'justify-start', label: 'Left', icon: 'alignLeft' },
          { value: 'justify-center', label: 'center', icon: 'alignJustify' },
          { value: 'justify-end', label: 'Right', icon: 'alignRight' },
        ],
        tab: 'style',
      },
      buttonBackgroundColor: {
        label: 'Background Color',
        type: 'color',
        default: '#dfc749',
        tab: 'style',
      },
      buttonFontColor: {
        label: 'Text Color',
        type: 'color',
        default: '#ffff',
        tab: 'style',
      },
      buttonStyleHr: {
        type: 'hrTag',
        tab: 'style',
      },
      buttonHeading: {
        label: 'Border',
        type: 'heading',
        tab: 'style',
      },
      borderType: {
        label: 'Border Type',
        type: 'select',
        default: 'none',
        options: [
          { value: 'none', label: 'None' },
          { value: 'dashed', label: 'Dashed' },
          { value: 'dotted', label: 'Dotted' },
          { value: 'double', label: 'Double' },
          { value: 'groove', label: 'Groove' },
          { value: 'hidden', label: 'Hidden' },
          { value: 'solid', label: 'Solid' },
        ],
        tab: 'style',
      },
      borderColor: {
        label: 'Border Color',
        type: 'color',
        default: '#ffffff',
        tab: 'style',
      },
      borderWidth: {
        label: 'Border Width',
        type: 'sizing',
        inputType: 'Width',
        default: [0, 0, 0, 0],
        tab: 'style',
      },
      borderRadius: {
        label: 'Border Radius',
        type: 'sizing',
        inputType: 'Radius',
        default: [0, 0, 0, 0],
        tab: 'style',
      },
      globalStyling: {
        textColor: '#594255',
        fontColor: '#ffff',
        fontSize: '14px',
        groundColor: '#dfc749',
        status: false,
        ignoreFieldArray: [],
        globalChangesList: [],
      },
    },
    divider: [
      {
        label: 'Fields',
        value: [
          'fields',
          'labelShow',
          'rowGap',
          'labelHr',
          'labelHeading',
          'labelSpacing',
          'labelTextColor',
          'labelTextTypography',
          'fielsHr',
          'fielsHeading',
          'fieldTextColor',
          'fieldTextTypography',
        ],
      },
      {
        label: 'Buttons',
        value: [
          'buttonSize',
          'buttonWidth',
          'buttonHr',
          'buttonLabel',
          'icon',
          'iconPosition',
          'iconSpacing',
          'buttonPosition',
          'buttonAlignmenrt',
          'buttonBackgroundColor',
          'buttonFontColor',
          'buttonStyleHr',
          'buttonHeading',
          'borderType',
          'borderColor',
          'borderWidth',
          'borderRadius',
        ],
      },
    ],
    id: `form-4`,
  });

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await getAllCards();
        const parsedCards: ParsedCard[] = response.map((card: Card) => ({
          ...card,
          global_cardStyle: JSON.parse(card.global_cardStyle),
          items: JSON.parse(card.items),
        }));
        setCards(parsedCards);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching cards:', error);
        setIsLoading(false);
      }
    };

    fetchCards();
  }, []);

  const closeModal = () => {
    setCardUrl('');
    setIsQrCodeModalOpen(false);
  };

  const [selectedCardId, setSelectedCardId] = useState<number | null>();
  const [deletedCardDetail, setDeletedCardDetail] = useState<any | null>();
  const [deleteVisible, setDeleteVisible] = useState(false);

  const handleDeleteCard = async (card: any) => {
    if (card.card_type == 'master') {
      // alert('Default card cannot be deleted.');
      setdeleteCardErrorModal(true);
      return;
    } else {
      setDeletedCardDetail(card);
      setSelectedCardId(card.id);
      setDeleteVisible(true);
    }
  };

  const confirmDeleteCard = async () => {
    if (selectedCardId) {
      try {
        const response = await deleteCard(selectedCardId);
        if (response.message === 'Card deleted successfully') {
          let sendBy = 'user';
          if (selectUserDetails?.user?.user_role === 'CEO') {
            sendBy = 'user';
          } else {
            sendBy = 'team_mate';
          }
          const messagesInfo = {
            message: `A  card named ${deletedCardDetail?.card_name} has been deleted by  ${selectUserDetails?.user?.first_name} ${selectUserDetails?.user?.last_name}`,
            subject: 'card',
            cardName: deletedCardDetail?.card_name,
            send_by: sendBy,
            user_id: selectUserDetails?.user?.id,
            notify_user_id: selectUserDetails?.user.notify_user_id,
          };
          await PushInAppNotification(messagesInfo);
        }

        setCards(cards.filter((card) => card.id !== selectedCardId));
        setSelectedCardId(null);
        setDeleteVisible(false);
      } catch (error) {
        console.error('Error deleting card:', error);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == 'cardName') {
      setCardName(value);
    }
    if (name == 'cardDescription') {
      setCardDescription(value);
    }
    setCardError('');
  };

  const handlePublishNewCard = async () => {
    let error = false;
    if (!cardName && !cardDescription) {
      setCardError('Card name and description are required');
    } else if (!cardName) {
      setCardError('Card name is required');
    } else if (!cardDescription) {
      setCardError('Card description is required');
    } else {
      setCardError('');
    }

    // Check if card name already exists
    const cardNameExists = cards.some(
      (card) => card.card_name.toLowerCase() === cardName.toLowerCase(),
    );
    if (cardNameExists) {
      setCardError('Card name already exists');
      error = true;
    }

    if (!cardName || !cardDescription || cardError !== '' || error) {
      return;
    }

    try {
      setHandleNewCardLoading(true);
      let userdataParse: any = {};
      // ****** fetching current user *********
      try {
        const res = await ProfileData();

        let sendBy = 'user';
        if (selectUserDetails?.user?.user_role === 'CEO') {
          sendBy = 'user';
        } else {
          sendBy = 'team_mate';
        }
        const messagesInfo = {
          message: `A new card named ${cardName} has been created by  ${res?.data?.user.first_name} ${res?.data?.user.last_name}`,
          subject: 'card',
          cardName: cardName,
          send_by: sendBy,
          user_id: selectUserDetails?.user?.id,
          notify_user_id: selectUserDetails?.user.notify_user_id,
        };
        await PushInAppNotification(messagesInfo);
        if (res?.data.success) {
          userdataParse = {
            ...res.data.user,
            job_title: res.data.user.job_title
              ? JSON.parse(res.data.user.job_title)
              : [''],
          };
        } else {
          logout();
          navigate('/login');
          return;
        }
      } catch {
        console.error('Error fetching user data');
        setHandleNewCardLoading(false);
        return;
      }

      // ****** end fetching current user *********
      // ****** creating new card *********
      let updatedComponent = {};
      const formCompoent = formComponent();
      if (Object.keys(userdataParse).length !== 0) {
        const component = profileComponent();

        if (component.type === 'profile') {
          updatedComponent = {
            ...component,
            customizationOptions: {
              ...component.customizationOptions,
              fname: {
                ...component.customizationOptions.fname,
                default: userdataParse?.first_name || 'fname',
              },
              lname: {
                ...component.customizationOptions.lname,
                default: userdataParse?.last_name || 'lname',
              },
              companyName: {
                ...component.customizationOptions.companyName,
                default: userdataParse?.company_name || 'Company Name',
              },
              profileImage: {
                ...component.customizationOptions.profileImage,
                default: userdataParse?.profile_picture || 'profile.jpg',
              },
              jobTitles: {
                ...component.customizationOptions.jobTitles,
                default: userdataParse?.job_title || ['Job Title'],
              },
            },
          };
        }
      } else {
        setHandleNewCardLoading(false);
        return;
      }
      // ****** end creating new card *********
      const newCard = {
        cardType: 'normal',
        card_name: cardName,
        card_description: cardDescription,
        selectedTheme: 'theme1s',
        items: [updatedComponent, formCompoent],
      };
      const response = await createCard(newCard);
      if (response.cardId) {
        // navigate(`/theme1s/edit-card/${response.cardId}`);

        navigate(`/theme1s/edit-card`, {
          state: { cardId: response.cardId },
        });
      }
    } catch (error) {
      console.error('Error during card creation:', error);
    } finally {
      setHandleNewCardLoading(false);
    }
  };

  // const formatCardName = (name: string) => name.replace(/\s+/g, '_');
  const formatCardName = (name: string) =>
    name.replace(/\s+(\w)/g, (_, firstLetter) => firstLetter.toUpperCase());

  const secretKey = 2.5;
  const multiplier = 39;
  const offset = 5;

  const handleAiTextGeneration = async () => {
    if (!promptVal.trim()) {
      setPromptError('Please enter a prompt');
      return;
    }
    setPromptError('');

    try {
      setGenerating(true);
      const data = promptVal;

      const response = await generateText({ data });

      if (response?.data.success) {
        setGenerating(false);
        const result = response?.data;
        if (result.text) {
          setCardDescription(result.text);
        }
        setPromptVal('');
        setPromptError('');
        setPromptModelOpen(false);
      } else {
        setGenerating(false);

        setPromptVal('');
        setPromptError(response?.data.error);
      }
    } catch (error) {
      setGenerating(false);
      console.error('Error generating AI text:', error);
      setPromptError('Error generating AI text. Please try again later!');
    }
  };

  const closePromptModal = () => {
    setPromptModelOpen(false);
    setPromptVal('');
    setPromptError('');
  };

  //  ************* website tour *************
  const { tourStep, setTourStep } = useTour();

  useEffect(() => {
    // const tourCompleted = localStorage.getItem('tourCompleted');
    if (cards?.length > 0) {
      setTimeout(() => {
        // Check if the tour has already been completed
        if (selectUserDetails?.user.tour_guide == 0 && tourStep === 1) {
          startTour();
        }
      }, 200);
    } else {
      setTimeout(() => {
        // Check if the tour has already been completed
        if (selectUserDetails?.user.tour_guide == 0 && tourStep === 1) {
          startNoCardTour();
        }
      }, 300);
    }
  }, [tourStep, cards]);

  // Start the tour for Page 1
  const startTour = () => {
    const tour = driver({
      showProgress: true,
      doneBtnText: 'Next →',
      steps: [
        {
          element: '#creat-card',
          popover: {
            title: 'Create New Card',
            description:
              'Begin your journey by clicking here to create custom cards tailored to your needs.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#view-card',
          popover: {
            title: 'View Card',
            description:
              'Click here to view the details of your selected card and explore its features.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#edit-card',
          popover: {
            title: 'Edit Card',
            description:
              'Make adjustments to your card by clicking here. You can update information and settings as needed.',
            side: 'bottom',
            align: 'start',
          },
        },
        {
          element: '#delete-card',
          popover: {
            title: 'Delete Card',
            description:
              'Remove the card from your collection by clicking here.',
            side: 'bottom',
            align: 'start',
          },
        },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#delete-card') {
          onCompleteTour();
        } else {
          // localStorage.setItem('tourCompleted', 'true');
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
    });

    tour.drive();
  };

  //start this tour when there is no cards
  const startNoCardTour = () => {
    const tour = driver({
      showProgress: true,
      doneBtnText: 'Next →',
      steps: [
        {
          element: '#creat-card',
          popover: {
            title: 'Create New Card',
            description:
              'Begin your journey by clicking here to create custom cards tailored to your needs.',
            side: 'left',
            align: 'start',
          },
        },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#creat-card') {
          onCompleteTour();
        } else {
          // localStorage.setItem('tourCompleted', 'true');
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
    });

    tour.drive();
  };

  // Navigate to the next page and update the step
  const onCompleteTour = () => {
    setTourStep(2);
    navigate('/analytics');
  };

  return (
    <DefaultLayout title="My Cards">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-end">
            <button
              id="creat-card"
              className={`flex mb-4 flex-row gap-3 items-center bg-purpletacx rounded-xl px-5 py-2 text-white ${cards?.length == 0 ? 'cursor-not-allowed ' : 'cursor-pointer block'}`}
              onClick={() => setCardModal(true)}
              disabled={cards?.length == 0}
            >
              <span>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.704 17.364V10.452H0.224V7.788H6.704V0.875999H9.512V7.788H15.992V10.452H9.512V17.364H6.704Z"
                    fill="white"
                  />
                </svg>
              </span>
              Add new
            </button>
          </div>
          <div>
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-4">
              {cards?.length > 0 ? (
                cards.map((card) => {
                  const encodeIds = (id: any) => {
                    return (id * multiplier + offset) ^ secretKey;
                  };

                  const encodedId = card.id ? encodeIds(card.id) : null;

                  return (
                    <>
                      <div
                        className="bg-white shadow-md rounded-xl md:w-[90%]"
                        key={card.id}
                      >
                        <div className="flex gap-10 flex-row items-center justify-center border-b border-[#EDEDED] py-3">
                          <p className="text-purpletacx font-semibold">
                            {card.card_type === 'master'
                              ? 'Default'
                              : card?.card_name}
                          </p>
                          <div className="flex flex-row gap-2 items-center justify-end">
                            <Link
                              id="view-card"
                              data-tooltip-id="view-card"
                              to={`/${
                                card.card_type === 'master'
                                  ? `${card.card_type}-${encodedId}`
                                  : `${card.card_name !== null ? formatCardName(card.card_name) : card.card_name}-${
                                      encodedId
                                    }`
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                width="31"
                                height="32"
                                viewBox="0 0 31 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="15.6149"
                                  cy="15.8825"
                                  r="15.1461"
                                  fill="#594255"
                                />
                                <path
                                  d="M19.3333 11C13.0218 11 10.1503 16.711 10.1503 16.711L10 17.0143L10.1516 17.3152C10.1516 17.3152 13.0237 23 19.3333 23C25.6448 23 28.5163 17.2891 28.5163 17.2891L28.6667 16.9857L28.515 16.6849C28.515 16.6849 25.6429 11 19.3333 11ZM19.3333 12.3334C24.3251 12.3334 26.7167 16.199 27.1582 16.987C26.7189 17.7752 24.3267 21.6667 19.3333 21.6667C14.3415 21.6667 11.9499 17.801 11.5084 17.013C11.9477 16.2248 14.34 12.3334 19.3333 12.3334ZM19.377 13.6536C17.5682 13.6536 16.0877 15.1539 16.0877 16.987C16.0877 18.82 17.5682 20.3204 19.377 20.3204C21.1858 20.3204 22.6663 18.82 22.6663 16.987C22.6663 15.1539 21.1858 13.6536 19.377 13.6536ZM19.377 14.987C20.4748 14.987 21.3506 15.8745 21.3506 16.987C21.3506 18.0994 20.4748 18.987 19.377 18.987C18.2792 18.987 17.4034 18.0994 17.4034 16.987C17.4034 15.8745 18.2792 14.987 19.377 14.987Z"
                                  fill="white"
                                  transform="translate(-3, -2)"
                                />
                              </svg>
                            </Link>
                            <NavLink
                              id="edit-card"
                              data-tooltip-id="edit-card"
                              to={`/${card.selected_theme}/edit-card`}
                              state={{ cardId: card.id }}
                            >
                              <MyIcon iconName="editCard" />
                            </NavLink>

                            <button
                              id="delete-card"
                              data-tooltip-id="delete-card"
                              onClick={() => handleDeleteCard(card)}
                            >
                              <MyIcon iconName="deleteCard" />
                            </button>
                          </div>
                        </div>

                        <div className="py-4">
                          <div className="relative mx-auto border-black bg-black border-[10px] rounded-[2.5rem] h-[480px] w-[240px]">
                            <div className="h-[32px] w-[3px] bg-black absolute -start-[13px] top-[72px] rounded-s-lg"></div>
                            <div className="h-[46px] w-[3px] bg-black absolute -start-[13px] top-[124px] rounded-s-lg"></div>
                            <div className="h-[46px] w-[3px] bg-black absolute -start-[13px] top-[178px] rounded-s-lg"></div>
                            <div className="h-[64px] w-[3px] bg-black absolute -end-[13px] top-[142px] rounded-e-lg"></div>
                            <div className="rounded-[2rem] overflow-hidden w-[220px] h-[460px] bg-white">
                              <PreviewCard
                                isOpen={true}
                                items={card.items}
                                globalCardStyle={card.global_cardStyle}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <>
                  <div className="bg-white shadow-md rounded-xl w-full p-6">
                    <p className="text-black text-lg">
                      You need to save your information to create your default
                      card and enjoy using the TACX card editor.
                    </p>
                    <div className="mt-4">
                      <Link
                        to={'/my-profile/edit-information'}
                        className="bg-yellowtacx px-4 py-2 rounded-lg text-md text-purpletacx font-semibold"
                      >
                        Go to edit information
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {cardModal && (
            <>
              <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 ">
                <div className="bg-white flex flex-col p-5 m-auto rounded-lg w-[80%] md:w-[40%] sm:w-[40%] lg:w-[30%] slideInFromTop-animate">
                  <div className="flex flex-row justify-between">
                    <p className="text-purpletacx text-lg font-semibold ">
                      Create Card
                    </p>
                    <button
                      onClick={() => {
                        setCardModal(false);
                        setCardName('');
                        setCardDescription('');
                        setCardError('');
                      }}
                    >
                      <svg
                        width="15"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8307 15.8346L3.16406 3.16797M15.8307 3.16797L3.16406 15.8346"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                  {/* <p>How would you like to add the users?</p> */}
                  <div className="flex flex-col gap-3 mt-5 mb-4">
                    <div className="flex flex-col ">
                      <label className="text-sm text-purpletacx font-medium">
                        Card Name
                      </label>
                      <input
                        type="text"
                        name="cardName"
                        className="bg-gray rounded-lg py-1.5 px-2 mt-1 text-sm"
                        placeholder="Ex: California Sales Team"
                        // onChange={(e) => setCardName(e.target.value)}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <label className="text-sm text-purpletacx font-medium">
                          Description
                        </label>
                        <button
                          className="flex gap-1 items-end"
                          // onClick={handleAiPromptVisible}
                          onClick={() => {
                            setPromptModelOpen(true);
                          }}
                        >
                          <MyIcon iconName="geminiAi" />
                          <p className="text-xs text-yellowtacx font-medium">
                            Write with AI
                          </p>
                        </button>
                      </div>
                      <textarea
                        name="cardDescription"
                        rows={5}
                        className="bg-gray rounded-lg py-1.5 px-2 mt-1 text-sm"
                        placeholder="Ex: Card design for sales team"
                        value={cardDescription}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {cardError && (
                    <p className="text-sm text-red-500">{cardError}</p>
                  )}

                  <div className="mt-5 flex flex-row flex-wrap lg:flex-nowrap gap-4 lg:gap-1 justify-around">
                    <button
                      className="bg-purpletacx w-full py-1.5  text-white rounded-full"
                      onClick={handlePublishNewCard}
                    >
                      {handleNewCardLoading ? (
                        <>
                          <div className="inline-block h-4 w-4 mr-2 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                          <span>Creating Card...</span>
                        </>
                      ) : (
                        'Create Card'
                      )}
                    </button>
                    {!handleNewCardLoading && (
                      <button
                        className="border border-purpletacx w-full py-1.5 text-purpletacx rounded-full"
                        onClick={() => {
                          setCardModal(false);
                          setCardName('');
                          setCardDescription('');
                          setCardError('');
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {deleteCardErrorModal && (
            <>
              <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
                <div className="bg-[#ffff] flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                  <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                    <MyIcon iconName="error" />
                    <div className="mt-2 text-center text-purpletacx">
                      <p className="font-extrabold text-xl">Cannot Delete</p>
                      <p className="text-md">Default card cannot be deleted.</p>
                    </div>
                    <div
                      className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-yellowtacx text-purpletacx font-bold cursor-pointer"
                      onClick={() => setdeleteCardErrorModal(false)}
                    >
                      Okay!
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {deleteVisible && (
            <>
              <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
                <div className="bg-white flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                  <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                    <div className="flex justify-center">
                      <svg
                        className="w-20 h-20 text-red-500 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="68"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </div>
                    <div className="mt-2 text-center text-black">
                      <p className="font-extrabold text-2xl">Delete Card</p>
                      <p className="text-sm">
                        Are you sure you want to delete this card?{' '}
                      </p>
                      <p className="text-sm">This action cannot be undone. </p>
                    </div>
                    <div className="gap-2 flex mt-6">
                      <button
                        className="bg-yellowtacx text-black px-10 py-2 rounded-xl"
                        onClick={() => {
                          setDeleteVisible(false);
                          setSelectedCardId(null);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-red-700 text-white px-10 py-2 rounded-xl"
                        onClick={confirmDeleteCard}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <QrCodeModel
        onClose={closeModal}
        url={cardUrl}
        isOpen={isQrCodeModalOpen}
      />
      <PromptModal
        isOpen={promptModelOpen}
        promptVal={promptVal}
        setPromptVal={setPromptVal}
        promptError={promptError}
        handleSubmit={handleAiTextGeneration}
        closePromptModal={closePromptModal}
        generating={generating}
      />
      <ReactTooltip
        id="view-card"
        // place="bottom"
        content="View Card"
      />
      <ReactTooltip id="edit-card" content="Edit Card" />
      <ReactTooltip id="delete-card" content="Delete Card" />
    </DefaultLayout>
  );
};

export default MyCard;
