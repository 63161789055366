import React, { useEffect, useState } from 'react';
import CardDataStats from '../../components/Carddatastats';
import ChartTwo from '../../components/charts/Charttwo';
import DefaultLayout from '../../layout/Defaultlayout';
import MyIcon from '../../components/cardeditor/icons/Myicon';
import { getCardEngagementData, guideTourComplete } from '../../api/Api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/Userslice';
import Loader from '../../common/loader/Index';
import { useTour } from '../../Authcontext';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select, { StylesConfig } from 'react-select';
import { CiCalendarDate } from 'react-icons/ci';
import {
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from 'date-fns';

interface EngagementDataProps {
  pageView: number;
  connection: number;
  viewPerUser: any;
  engagementTime: any;
}
const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0 10px',
    borderColor: '#D8D8D8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any; isDisabled: boolean }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isDisabled ? '#A9A9A9' : state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: state.isDisabled ? '#fff' : '#DFC749',
      color: state.isDisabled ? '#A9A9A9' : '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
    zIndex: 99,
  }),
  menuList: (provided) => ({
    ...provided,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Scrollbar thumb hover color
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const option = [
  { value: '', label: 'Select', isDisabled: true },
  { value: 'all', label: 'All' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'this week', label: 'This week' },
  { value: 'last week', label: 'Last week' },
  { value: 'this month', label: 'This month' },
  { value: 'last month', label: 'Last month' },
  { value: 'custom', label: 'Custom' },
];
const Analytics: React.FC = () => {
  const navigate = useNavigate();
  const userDetails = useSelector(selectUser);
  const userData = userDetails?.user;
  const [loading, setLoading] = useState<boolean>(false);
  const [engagementData, setEngagementData] = useState<EngagementDataProps>();
  const [chartData, setChartData] = useState([]);
  const [selectedOption, setSelectedOption] = useState<string>('all');
  const [startDates, setStartDates] = useState<Date | undefined>(undefined);
  const [endDates, setEndDates] = useState<Date | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (userData.card_id && userData.card_id !== null) {
      CardEngagementData(userData.card_id, startDates, endDates);
    }
  }, [userData]);

  const CardEngagementData = async (
    userId: any,
    startDates: Date | undefined,
    endDates: Date | undefined,
  ) => {
    try {
      setLoading(true);
      const response = await getCardEngagementData(userId);
      const Data = response?.data;
      // Initialize metrics
      let pageView = 0;
      let totalEngagementTime = 0;
      let totalViews = 0;
      let viewPerUser = 0;
      let engagementTime = 0;
      let connection = 0;

      if (startDates && endDates) {
        // Filter data based on selected date range
        const filteredData = Data.filter((item: any) => {
          const createdAt = new Date(item.created_at);
          const startDate = new Date(startDates);
          const endDate = new Date(endDates);
          // Normalize dates to start of the day
          const normalizeDate = (date: Date) => {
            return new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
            );
          };
          const createdAtNormalized = normalizeDate(createdAt);
          const startDateNormalized = normalizeDate(startDate);
          const endDateNormalized = normalizeDate(endDate);
          // Ensure dates are within the same timezone and compare
          return (
            createdAtNormalized >= startDateNormalized &&
            createdAtNormalized <= endDateNormalized
          );
        });
        // Process response
        filteredData.forEach((data: any) => {
          pageView += data.view_count;
          totalEngagementTime += data.engagement_time;
          totalViews += 1; // Count each visitor
        });
        // Calculate metrics
        viewPerUser =
          totalViews > 0 ? parseFloat((pageView / totalViews).toFixed(2)) : 0;
        engagementTime =
          totalViews > 0
            ? parseFloat((totalEngagementTime / totalViews).toFixed(2))
            : 0;
        connection = new Set(filteredData.map((entry: any) => entry.visitor_id))
          .size;
        setChartData(filteredData);
      } else {
        // Process response
        Data.forEach((data: any) => {
          pageView += data.view_count;
          totalEngagementTime += data.engagement_time;
          totalViews += 1; // Count each visitor
        });
        // Calculate metrics
        viewPerUser =
          totalViews > 0 ? parseFloat((pageView / totalViews).toFixed(2)) : 0;
        engagementTime =
          totalViews > 0
            ? parseFloat((totalEngagementTime / totalViews).toFixed(2))
            : 0;
        connection = new Set(Data.map((entry: any) => entry.visitor_id)).size;
        setChartData(Data);
      }

      // Set state with calculated values
      setEngagementData({
        pageView,
        connection,
        viewPerUser,
        engagementTime,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const formatTime = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    let timeString = '';
    if (hours > 0) {
      timeString += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      timeString += `${minutes}m `;
    }
    if (hours === 0) {
      timeString += `${remainingSeconds}s`;
    }
    return timeString.trim();
  };
  const engagementTimeFormate = formatTime(engagementData?.engagementTime);

  //  ************* Filter Implementation *************
  const handleDropdownChange = (selected: any) => {
    setSelectedOption(selected.value);
    let startDate = undefined;
    let endDate = undefined;

    switch (selected.value) {
      case 'all':
        startDate = undefined;
        endDate = undefined;
        break;
      case 'today':
        startDate = new Date();
        endDate = new Date();
        break;
      case 'yesterday':
        startDate = subDays(new Date(), 1);
        endDate = subDays(new Date(), 1);
        break;
      case 'this week':
        startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
        endDate = endOfWeek(new Date(), { weekStartsOn: 1 });
        break;
      case 'last week':
        startDate = subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7);
        endDate = subDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 7);
        break;
      case 'this month':
        startDate = startOfMonth(new Date());
        endDate = endOfMonth(new Date());
        break;
      case 'last month':
        startDate = startOfMonth(subDays(new Date(), 30));
        endDate = endOfMonth(subDays(new Date(), 30));
        break;
      case 'custom':
        startDate = new Date();
        endDate = new Date();
        break;
      default:
        break;
    }
    setStartDates(startDate);
    setEndDates(endDate);
    if (startDate && endDate) {
      CardEngagementData(userData.card_id, startDate, endDate);
    } else {
      CardEngagementData(userData.card_id, undefined, undefined);
    }
  };
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDates(start);
    setEndDates(end);
    if (start && end) {
      CardEngagementData(userData.card_id, start, end);
    }
  };
  //  ************* website tour *************
  const { tourStep, setTourStep } = useTour();

  useEffect(() => {
    setTimeout(() => {
      // Check if the tour has already been completed
      // const tourCompleted = localStorage.getItem('tourCompleted');
      if (userData?.tour_guide == 0 && tourStep === 2) {
        startTour();
      }
    }, 300);
  }, [tourStep, engagementData]);

  // Start the tour for Page 1
  const startTour = () => {
    const tour = driver({
      showProgress: true,
      doneBtnText: 'Next →',
      steps: [
        {
          element: '#card-analytics-data',
          popover: {
            title: 'Analytics Card Data',
            description:
              'Explore the analytical insights of your cards, including performance metrics and user interactions.',
            side: 'left',
            align: 'start',
          },
        },
        {
          element: '#chart-one',
          popover: {
            title: 'Visitor Chart',
            description:
              'Visualize the number of visitors over time with this chart. It helps you understand trends and user engagement.',
            side: 'left',
            align: 'start',
          },
        },
        // {
        //   element: '#chart-two',
        //   popover: {
        //     title: 'Engagement Chart',
        //     description:
        //       'Analyze user engagement levels through this chart, highlighting interactions and activity on your cards.',
        //     side: 'bottom',
        //     align: 'start',
        //   },
        // },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#chart-one') {
          onCompleteTour();
        } else {
          // localStorage.setItem('tourCompleted', 'true');
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
    });

    tour.drive();
  };

  // Navigate to the next page and update the step
  const onCompleteTour = () => {
    if (userData?.user_role == 'Team mate') {
      navigate('/my-activity');
      setTourStep(6);
    } else {
      navigate('/users');
      setTourStep(3);
    }
  };
  return (
    <DefaultLayout title="Analytics">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col-reverse sm:flex-row justify-end gap-2 w-full mb-5">
            {selectedOption === 'custom' && (
              <>
                <div className="mr-5 flex items-center">
                  <div className="border-l border-y border-[#D8D8D8] px-3 py-[8.5px] rounded-l-[11px]">
                    <CiCalendarDate
                      style={{
                        color: '#000',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </div>
                  <DatePicker
                    selected={startDates}
                    onChange={onChange}
                    startDate={startDates}
                    endDate={endDates}
                    selectsRange
                    dateFormat="dd/MM/yyyy"
                    className="border border-[#D8D8D8] w-[115%] sm:w-[111%] text-black px-3 py-[6.5px] rounded-r-[11px]"
                    maxDate={new Date()}
                  />
                </div>
              </>
            )}
            <div className="relative w-[53%] sm:w-[40%] md:w-[30%] lg:w-[20%]">
              <Select
                options={option}
                styles={customStyles}
                onChange={handleDropdownChange}
                value={option.find((option) => option.value === selectedOption)}
                placeholder="Select"
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
                id="card-engagement-filter"
              />
              <span
                className={`absolute top-0 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                  isOpen ? 'rotate-180' : 'rotate-0'
                } `}
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                </svg>
              </span>
            </div>
          </div>
          <div
            id="card-analytics-data"
            className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5"
          >
            <CardDataStats
              title="Page view"
              total={engagementData?.pageView}
              rate="0.43%"
              levelUp
            >
              <MyIcon iconName="pageView" />
            </CardDataStats>
            <CardDataStats
              title="Connections"
              total={engagementData?.connection}
              rate="4.35%"
              levelUp
            >
              <MyIcon iconName="connection" />
            </CardDataStats>
            <CardDataStats
              title="View Per User"
              total={engagementData?.viewPerUser}
              rate="2.59%"
              levelUp
            >
              <MyIcon iconName="viewPerUser" />
            </CardDataStats>
            <CardDataStats
              title="Engagement Time"
              total={engagementTimeFormate}
              rate="0.95%"
              levelDown
            >
              <MyIcon iconName="engagementTime" />
            </CardDataStats>
          </div>

          <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <ChartTwo chartData={chartData} />
            {/* <ChartThree chartData={chartData} /> */}
          </div>
        </>
      )}
    </DefaultLayout>
  );
};
export default Analytics;
