import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NoInternetPage from './components/Network/InternetStatus';
import NoInternetGif from './assets/reshot-icon-wan-4G5TC7LPV3.svg'; 
import {InternetStatusProvider} from './Context/InternatStatusContext'
import { useAuth } from './Authcontext';
import Loader from './common/loader/Index';
import PageTitle from './components/Pagetitle';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PubliceRoute';
import LandingPage from './components/landing/Landingpage';
import NotFound from './pages/Notfound';

const SignUp = lazy(() => import('./pages/authentication/Signup'));
const CompleteSignUp = lazy(
  () => import('./pages/authentication/Completesignup'),
);
const CompleteGoogleSignup = lazy(
  () => import('./pages/authentication/Completegooglesignup'),
);
const Login = lazy(() => import('./pages/authentication/Login'));
const ForgotPassword = lazy(
  () => import('./pages/authentication/Forgotpassword'),
);
const Plan = lazy(() => import('./pages/plans/Plan'));
const CheckOut = lazy(() => import('./pages/plans/Checkout'));
const EditInformation = lazy(() => import('./pages/myprofile/EditInformation'));
const CardEditor = lazy(() => import('./pages/cardeditor/Editor'));
const SavedCard = lazy(() => import('./pages/cardeditor/Savedcard'));
const ContactUs = lazy(()=> import('./pages/contactus/Contactus'))
import MyProfile from './pages/myprofile/Myprofile';
import Users from './pages/User';
import Analytics from './pages/analytics/Analytics';
import QuotesMessages from './pages/quotemessage/Quotemessage';
import UserSupport from './pages/Supportuser/SupportUserMessage';
import AccountAndBilling from './pages/accountbilling/Accountandbilling';
import UserSettings from './pages/settings/Usersetting';
import MyCard from './pages/Mycard';
import UpgradePlan from './pages/plans/Upgradeplan';
import Integration from './pages/Integration/CRMIntegration';
import Myactivity from './pages/myactivity/Myactivity';

// Admin Routes
const AdminLogin = lazy(() => import('./pages/admin/Adminlogin'));
const AdminForgotPassword = lazy(
  () => import('./pages/authentication/Adminforgotpassword'),
);
import Adminprofile from './pages/admin/Adminprofile/Adminprofile';
import AdminDashboard from './pages/admin/Admindashboard/Admindashboard';
import ProfileEdit from './pages/admin/AdminProfileEdit/ProfileEdit';
import UsersSubscriptions from './pages/admin/UserSubcscriptions/Usersubscriptions';
import UserDetail from './components/UserDetail/Userdetail';
import userSubscription from './components/UserDetail/ViewUserSubscriptions';
import AdminClientdashboard from './pages/admin/AdminClientdashboard/AdminClientdashboard';
import AdminActivity from './pages/admin/AdminActivity/Adminactivity';
import AnalyticsAdmin from './pages/admin/AdminAnalytics/AdminAnalytics';
import adminSupport from './pages/admin/AdminSupport/AdminSupport';
import emailTemplate from './pages/admin/EmailTemplate/EmailTemplate';
import Contactus from './pages/admin/ContactUs/ContactUs';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  // const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const { pathname } = useLocation();
  const [savedCards] = useState([]);
  const { isAuthenticated } = useAuth();
  // const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  // useEffect(() => {
  //   const handleOnline = () => {
  //     setIsOnline(true);
  //     const lastVisited = localStorage.getItem('lastVisitedUrl');
  //     if (lastVisited) {
  //       navigate(lastVisited);
  //       localStorage.removeItem('lastVisitedUrl');
  //     }
  //   };
  
  //   const handleOffline = () => {
  //     setIsOnline(false);
  //     localStorage.setItem('lastVisitedUrl', window.location.pathname);
  //     navigate('/no-internet');
  //   };
  
  //   window.addEventListener('online', handleOnline);
  //   window.addEventListener('offline', handleOffline);
  
  //   return () => {
  //     window.removeEventListener('online', handleOnline);
  //     window.removeEventListener('offline', handleOffline);
  //   };
  // }, [navigate]);
  


  return loading ? (
    <Loader />
  ) : (
    <>
    <InternetStatusProvider>
      <Suspense fallback={<Loader />}>
      
        <Routes>
          {/* plan page as a landing page  */}
          <Route
            index
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="TACX" />
                  <LandingPage />
                </>
              ) : (
                <>
                  <PageTitle title="MyProfile" />
                  <PrivateRoute element={MyProfile} />
                </>
              )
            }
          />

          <Route
            index
            path="/my-profile"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              ) : (
                <>
                  <PageTitle title="MyProfile" />
                  <PrivateRoute element={MyProfile} />
                </>
              )
            }
          />
          <Route
            index
            path="/purchase-plan"
            element={
              <>
                <PageTitle title="Plans" />
                <PublicRoute element={Plan} />
              </>
            }
          />
          <Route
            index
            path="/upgrade-plan"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PrivateRoute element={UpgradePlan} />
                </>
              )
            }
          />
          <Route
            path="/my-profile/edit-information"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Login" />
                  <Login />
                </>
              ) : (
                <>
                  <PageTitle title="MyProfile" />
                  <PrivateRoute element={EditInformation} />
                </>
              )
            }
          ></Route>

          <Route
            index
            path="/login"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Login" />
                  <Login />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/my-card"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="MyCard " />
                  <PrivateRoute element={MyCard} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/visitors"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Visitors" />
                  <PrivateRoute element={QuotesMessages} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/user/support"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="User Support" />
                  <PrivateRoute element={UserSupport} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />

          <Route
            path="/integration"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Integration" />
                  <PrivateRoute element={Integration} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />

          <Route
            path="/check-out"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Check Out" />
                  <PublicRoute element={CheckOut} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/analytics"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Analytics" />
                  <PrivateRoute element={Analytics} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />

          <Route
            path="/users"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Users" />
                  <PrivateRoute element={Users} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/account-billing"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Account and Billing" />
                  <PrivateRoute element={AccountAndBilling} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />

          <Route
            path="/settings"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Settings" />
                  <PrivateRoute element={UserSettings} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/theme/:themeName/editor"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="CardEditor" />
                  <PrivateRoute element={CardEditor} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/my-activity"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="MyActivity" />
                  <PrivateRoute element={Myactivity} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/:themeName/edit-card"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="CardEditor" />
                  <PrivateRoute element={CardEditor} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/:cardId"
            element={
              <>
                {/* <PageTitle title="Card" /> */}
                <SavedCard savedCards={savedCards} />
              </>
            }
          />

          <Route
            path="/signup"
            element={
              <>
                <PageTitle title="SignUp" />
                <SignUp />
              </>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <>
                <PageTitle title="Forgot-password" />
                <ForgotPassword />
              </>
            }
          />
          <Route
            path="/complete-signUp"
            element={
              <>
                <PageTitle title="Register Page" />
                <CompleteSignUp />
              </>
            }
          />
          <Route
            path="/complete-google-signUp"
            element={
              <>
                <PageTitle title="SignUp" />
                <CompleteGoogleSignup />
              </>
            }
          />

          <Route
            path="/contact-us"
            element={
              <>
                <PageTitle title="Contact Us" />
                <ContactUs />
              </>
            }
          />

          <Route
            path="/admin"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              )
            }
          ></Route>
          <Route
            path="/admin/dashboard"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Admin Dashboard" />
                  <PrivateRoute element={AdminDashboard} isAdminRoute />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              )
            }
          ></Route>
          <Route
            path="/admin/my-profile"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Admin Profile" />
                  <PrivateRoute element={Adminprofile} isAdminRoute />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              )
            }
          ></Route>
          <Route
            path="/admin/my-profile/edit-information"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Profile" />
                  <PrivateRoute element={ProfileEdit} isAdminRoute />
                </>
              )
            }
          ></Route>
          <Route
            path="/admin/users/subscriptions"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              ) : (
                <>
                  <PageTitle title="User Subscriptions" />
                  <PrivateRoute element={UsersSubscriptions} isAdminRoute />
                </>
              )
            }
          ></Route>
          <Route
            path="/admin/analytics"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              ) : (
                <>
                  <PageTitle title="Analytics" />
                  <PrivateRoute element={AnalyticsAdmin} isAdminRoute />
                </>
              )
            }
          ></Route>

          <Route
            path="/admin/dashboard/user-details/:userId"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              ) : (
                <>
                  <PageTitle title="User Information" />
                  <PrivateRoute element={UserDetail} isAdminRoute />
                </>
              )
            }
          ></Route>
          <Route
            path="/admin/users/subscription/view/page/:userId"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              ) : (
                <>
                  <PageTitle title="User Information" />
                  <PrivateRoute element={userSubscription} isAdminRoute />
                </>
              )
            }
          ></Route>
          <Route
            path="/admin/client/dashboard"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              ) : (
                <>
                  <PageTitle title="Client dashboard" />
                  <PrivateRoute element={AdminClientdashboard} isAdminRoute />
                </>
              )
            }
          ></Route>

          <Route
            path="admin/forgot-password"
            element={
              <>
                <PageTitle title="Admin Forgot-password" />
                <AdminForgotPassword />
              </>
            }
          />

          <Route
            path="/admin/user-activity"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="UserActivity" />
                  <PrivateRoute element={AdminActivity} isAdminRoute />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              )
            }
          />
          <Route
            path="/admin/support"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="UserActivity" />
                  <PrivateRoute element={adminSupport} isAdminRoute />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              )
            }
          />
          <Route
            path="/admin/email-template"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Email Template" />
                  <PrivateRoute element={emailTemplate} isAdminRoute />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              )
            }
          />
          <Route
            path="/admin/contact-us"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Contact Us" />
                  <PrivateRoute element={Contactus} isAdminRoute />
                </>
              ) : (
                <>
                  <PageTitle title="Admin Login" />
                  <AdminLogin />
                </>
              )
            }
          />
           <Route path="/no-internet" element={<NoInternetPage image={NoInternetGif} />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </Suspense>
      </InternetStatusProvider>
    </>
  );
}

export default App;
