import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/Defaultlayout';
import {
  getEmailTemplate,
  postEmailTemlate,
  updateEmailTemplate,
} from '../../../api/Adminapi';
import Loader from '../../../common/loader/Index';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'ckeditor5/ckeditor5.css';
import { RiEdit2Fill } from 'react-icons/ri';
import { MdOutlineEmail } from 'react-icons/md';

const UniqueSupportCard: React.FC = () => {
  const [emailTemplates, setEmailTemplates] = useState<any[]>([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateSucess, setUpdateSucess] = useState<boolean>(false);
  const [newTemplate, setNewTemplate] = useState({
    Title: '',
    subject: '',
    body: '',
  });
  const [currentTemplate, setCurrentTemplate] = useState<any | null>(null);

  const fetchEmailTemplates = async () => {
    try {
      setLoading(true);
      const res = await getEmailTemplate();
      const templates = res?.data?.text?.EmailTemplate[0] || [];
      setEmailTemplates(templates);
    } catch (error) {
      console.error('Fetch Email Template Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  const handleSaveNewTemplate = async () => {
    if (newTemplate.Title && newTemplate.subject && newTemplate.body) {
      try {
        setLoading(true);
        const payload = {
          title: newTemplate.Title,
          subject: newTemplate.subject,
          body: newTemplate.body,
        };
        const response = await postEmailTemlate(payload);

        if (response && response.data.success) {
          await fetchEmailTemplates();
          setIsAddingNew(false);
          setNewTemplate({ Title: '', subject: '', body: '' });
        } else {
          console.error('Error saving the template:', response?.data.message);
        }
      } catch (error) {
        console.error('API Error while saving template:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTemplateClick = (id: number) => {
    setIsAddingNew(false);
    const templateData = emailTemplates.find((template) => template.id === id);
    setCurrentTemplate(templateData); // Set the current template data
  };

  const changeEmailTemplate = async () => {
    if (currentTemplate) {
      try {
        setLoading(true);
        const response = await updateEmailTemplate(currentTemplate);

        if (response && response.data.success) {
          setEmailTemplates((prevTemplates) =>
            prevTemplates.map((template) =>
              template.id === currentTemplate.id
                ? { ...template, ...currentTemplate }
                : template,
            ),
          );
          setUpdateSucess(true);
          setTimeout(() => {
            setUpdateSucess(false);
          }, 3000);
        } else {
          setUpdateSucess(false);
          console.error('Error updating the template:', response?.data.message);
        }
      } catch (error) {
        setUpdateSucess(false);
        console.error('API Error while updating template:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAddNewTemplate = () => {
    setIsAddingNew(true);
    setNewTemplate({ Title: '', subject: '', body: '' });
    setCurrentTemplate(null); // Reset current template
  };

  const handleUpdateTemplate = (field: 'Subject' | 'Body', value: string) => {
    if (currentTemplate) {
      setCurrentTemplate((prev: any) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleCKEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setNewTemplate({ ...newTemplate, body: data });
    if (currentTemplate) {
      setCurrentTemplate({ ...currentTemplate, body: data });
    }
  };

  const handleBack = () => {
    setIsAddingNew(false);
    setCurrentTemplate(null);
  };

  return (
    <DefaultLayout title="Email Templates">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            {isAddingNew ? (
              <>
                <div className="flex justify-between items-center pb-3">
                  <div>
                    <h2 className="text-lg font-semibold mb-4">
                      Add New Template
                    </h2>
                  </div>
                  <div>
                    <button
                      className={`bg-purpletacx text-[#000] rounded-full px-3 py-3 transition-transform duration-300 `}
                      onClick={handleBack}
                    >
                      <svg
                        width="17"
                        height="15"
                        viewBox="0 0 19 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.83227 5.22791H1.31073C1.05147 5.22786 0.79806 5.15094 0.582517 5.00688C0.366975 4.86282 0.198983 4.65809 0.0997794 4.41857C0.000575467 4.17905 -0.0253864 3.91549 0.0251759 3.66122C0.0757382 3.40695 0.200554 3.17337 0.383845 2.99003L3.00586 0.368015C3.25312 0.129204 3.58428 -0.00293746 3.92802 4.95595e-05C4.27176 0.00303658 4.60058 0.140914 4.84365 0.383985C5.08672 0.627056 5.2246 0.955872 5.22759 1.29961C5.23057 1.64336 5.09843 1.97452 4.85962 2.22178L4.47681 2.6059H9.83227C10.18 2.6059 10.5134 2.74403 10.7593 2.98989C11.0051 3.23575 11.1433 3.56921 11.1433 3.91691C11.1433 4.26461 11.0051 4.59807 10.7593 4.84393C10.5134 5.08979 10.18 5.22791 9.83227 5.22791Z"
                          fill="#DFC749"
                        />
                        <path
                          d="M12.4546 14.4053H1.31101C0.963306 14.4053 0.629846 14.2672 0.383985 14.0213C0.138123 13.7754 0 13.442 0 13.0943C0 12.7466 0.138123 12.4131 0.383985 12.1673C0.629846 11.9214 0.963306 11.7833 1.31101 11.7833H12.4546C13.2423 11.7833 14.0037 11.4997 14.5994 10.9843C15.1951 10.4689 15.5853 9.75626 15.6987 8.97673C15.812 8.19721 15.6408 7.40294 15.2165 6.73927C14.7922 6.0756 14.1431 5.58691 13.388 5.36263C13.0548 5.26363 12.7746 5.03639 12.6089 4.73085C12.4433 4.42532 12.4057 4.0665 12.5045 3.73328C12.6033 3.40005 12.8304 3.11969 13.1358 2.95383C13.4412 2.78797 13.8 2.75018 14.1333 2.84877C15.4926 3.25218 16.6611 4.13159 17.4251 5.32605C18.1891 6.52051 18.4973 7.95014 18.2935 9.35329C18.0897 10.7564 17.3874 12.0393 16.3151 12.967C15.2429 13.8947 13.8724 14.4053 12.4546 14.4053Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="newTitle"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Template Title
                  </label>
                  <input
                    type="text"
                    id="newTitle"
                    value={newTemplate.Title}
                    onChange={(e) =>
                      setNewTemplate({
                        ...newTemplate,
                        Title: e.target.value,
                      })
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    placeholder="Enter template title"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="newSubject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="newSubject"
                    value={newTemplate.subject}
                    onChange={(e) =>
                      setNewTemplate({
                        ...newTemplate,
                        subject: e.target.value,
                      })
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    placeholder="Enter email subject"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="newBody"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Body
                  </label>
                  <div className="p-4 border border-gray-300 rounded-md">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        removePlugins: [
                          'ImageUpload',
                          'EasyImage',
                          'MediaEmbed',
                        ],
                      }}
                      data={newTemplate.body}
                      onChange={handleCKEditorChange}
                    />
                  </div>
                </div>
                <button
                  className="bg-yellowtacx text-sm md:text-base font-[500] rounded-lg text-black w-full sm:w-[165px] h-[40px] hover:bg-[#594255] hover:text-[#ffffff] transition-all duration-200"
                  onClick={handleSaveNewTemplate}
                >
                  Save New Template
                </button>
              </>
            ) : currentTemplate ? (
              <>
                {updateSucess && (
                  <>
                    <div className="absolute top-21 right-0 flex w-auto border-l-6 border-[#34D399] bg-[#34D399] bg-opacity-[15%] py-2 px-4 sm:pr-20 shadow-md slideInFromTop-animate">
                      <div className="w-full">
                        <h5 className=" text-md font-semibold text-black dark:text-[#34D399] ">
                          Changes saved successfully!
                        </h5>
                      </div>
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center pb-3">
                  <div>
                    <h2 className="text-lg font-semibold mb-4 text-purpletacx">
                      Edit Template
                    </h2>
                  </div>
                  <div>
                    <button
                      className={`bg-purpletacx text-[#000] rounded-full px-3 py-3 transition-transform duration-300 `}
                      onClick={handleBack}
                    >
                      <svg
                        width="17"
                        height="15"
                        viewBox="0 0 19 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.83227 5.22791H1.31073C1.05147 5.22786 0.79806 5.15094 0.582517 5.00688C0.366975 4.86282 0.198983 4.65809 0.0997794 4.41857C0.000575467 4.17905 -0.0253864 3.91549 0.0251759 3.66122C0.0757382 3.40695 0.200554 3.17337 0.383845 2.99003L3.00586 0.368015C3.25312 0.129204 3.58428 -0.00293746 3.92802 4.95595e-05C4.27176 0.00303658 4.60058 0.140914 4.84365 0.383985C5.08672 0.627056 5.2246 0.955872 5.22759 1.29961C5.23057 1.64336 5.09843 1.97452 4.85962 2.22178L4.47681 2.6059H9.83227C10.18 2.6059 10.5134 2.74403 10.7593 2.98989C11.0051 3.23575 11.1433 3.56921 11.1433 3.91691C11.1433 4.26461 11.0051 4.59807 10.7593 4.84393C10.5134 5.08979 10.18 5.22791 9.83227 5.22791Z"
                          fill="#DFC749"
                        />
                        <path
                          d="M12.4546 14.4053H1.31101C0.963306 14.4053 0.629846 14.2672 0.383985 14.0213C0.138123 13.7754 0 13.442 0 13.0943C0 12.7466 0.138123 12.4131 0.383985 12.1673C0.629846 11.9214 0.963306 11.7833 1.31101 11.7833H12.4546C13.2423 11.7833 14.0037 11.4997 14.5994 10.9843C15.1951 10.4689 15.5853 9.75626 15.6987 8.97673C15.812 8.19721 15.6408 7.40294 15.2165 6.73927C14.7922 6.0756 14.1431 5.58691 13.388 5.36263C13.0548 5.26363 12.7746 5.03639 12.6089 4.73085C12.4433 4.42532 12.4057 4.0665 12.5045 3.73328C12.6033 3.40005 12.8304 3.11969 13.1358 2.95383C13.4412 2.78797 13.8 2.75018 14.1333 2.84877C15.4926 3.25218 16.6611 4.13159 17.4251 5.32605C18.1891 6.52051 18.4973 7.95014 18.2935 9.35329C18.0897 10.7564 17.3874 12.0393 16.3151 12.967C15.2429 13.8947 13.8724 14.4053 12.4546 14.4053Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Template Name
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={currentTemplate.Title}
                    disabled
                    className="mt-1 block w-full rounded-md p-2"
                    placeholder="Enter email subject"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={currentTemplate.Subject}
                    onChange={(e) =>
                      handleUpdateTemplate('Subject', e.target.value)
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    placeholder="Enter email subject"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="body"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Body
                  </label>
                  <div className="p-4 border border-gray-300 rounded-md">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        removePlugins: [
                          'ImageUpload',
                          'EasyImage',
                          'MediaEmbed',
                        ], // Removes image upload options
                      }}
                      data={currentTemplate.Body}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleUpdateTemplate('Body', data);
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={changeEmailTemplate}
                  className="w-[50%] bg-customHover text-white p-2 rounded-md mt-4 hover:bg-yellowtacx hover:text-black"
                >
                  Save Changes
                </button>
              </>
            ) : (
              <>
                <div className="flex justify-between items-center pb-3">
                  <div className="flex gap-1 items-center">
                    <MdOutlineEmail
                      style={{
                        color: '#dfc749',
                        height: '40px',
                        width: '40px',
                      }}
                    />
                    <p className="text-lg font-semibold text-purpletacx">
                      Email Templates
                    </p>
                  </div>
                  <div>
                    <button
                      className="w-full text-sm bg-customHover text-white p-2 rounded-md mt-4 hover:bg-yellowtacx hover:text-black"
                      onClick={handleAddNewTemplate}
                    >
                      Add New Template
                    </button>
                  </div>
                </div>
                <hr className="text-yellowtacx w-[7.5%] rounded-md border-t-4" />

                <div className="mt-8">
                  <div className="flex justify-between px-4 py-2 text-white font-medium text-md xl:text-lg bg-purpletacx rounded-md">
                    <p>Template Name</p>
                    <p>Options</p>
                  </div>
                  {emailTemplates?.length > 0 ? (
                    emailTemplates.map((template) => (
                      <div className="flex justify-between px-4 py-3 text-black font-medium rounded-lg">
                        <div>{template.Title}</div>
                        <button
                          className="mr-4"
                          onClick={() => handleTemplateClick(template.id)}
                        >
                          <RiEdit2Fill
                            style={{
                              color: 'green',
                              height: '23px',
                              width: '23px',
                            }}
                          />
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="text-center px-6 py-3 text-black font-medium rounded-lg">
                      <p>No Template found</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default UniqueSupportCard;
