import { useNavigate } from 'react-router-dom';
import MyIcon from '../../components/cardeditor/icons/Myicon';
import { useEffect, useRef, useState } from 'react';
import { downgradePlan, getCountry, upgradePlan } from '../../api/Api';
import Header from '../../components/header/Subheader';
import Loader from '../../common/loader/Index';
import CustomLoader from '../../common/loader/Customloader';
import { useSelector } from 'react-redux';
import { selectSubscription } from '../../store/slice/Userslice';
import { GrUpgrade } from 'react-icons/gr';

const UpgradePlan = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addUser, setAddUser] = useState<number>(1);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const comparisonSectionRef = useRef<HTMLDivElement>(null);

  const [planPrices, setPlanPrices] = useState<{
    [key: string]: {
      monthly: number;
      yearly: number;
    };
  }>({
    Professional: {
      monthly: 199,
      yearly: 1990,
    },
    Team: {
      monthly: 499,
      yearly: 4990,
    },
    Enterprise: {
      monthly: 999,
      yearly: 9990,
    },
  });
  const [userLocation, setUserLocation] = useState('');
  const [loader, setLoader] = useState(true);
  const [upgradePlanLoader, setUpgradePlanLoader] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState<
    'monthly' | 'yearly'
  >('monthly');
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [isDowngrade, setIsDowngrade] = useState<boolean>(false);

  const subscriptionDetails = useSelector(selectSubscription);
  const isCurrentPlan = (plan: any, interval: any) =>
    subscriptionDetails?.plan_name === plan &&
    subscriptionDetails?.subscription_period === interval;

  const canSelectPlan = (plan: string, interval: string) => {
    const currentPlan = subscriptionDetails?.plan_name;
    const currentInterval = subscriptionDetails?.subscription_period;

    if (currentPlan === 'Enterprise' && currentInterval === 'year') {
      // Enterprise yearly: No plans should be available
      return false;
    }
    if (currentPlan === 'Enterprise' && currentInterval === 'month') {
      // Enterprise monthly: Can upgrade to Professional yearly, Team yearly, or Enterprise yearly
      return (
        (plan === 'Professional' && interval === 'year') ||
        (plan === 'Team' && interval === 'year') ||
        (plan === 'Enterprise' && interval === 'year')
      );
    }

    if (currentPlan === 'Team' && currentInterval === 'month') {
      // Team monthly: No downgrade to Professional monthly but can upgrade to Enterprise monthly or any yearly plans
      return (
        (plan === 'Enterprise' && interval === 'month') ||
        (plan === 'Enterprise' && interval === 'year') ||
        (plan === 'Professional' && interval === 'year') ||
        (plan === 'Team' && interval === 'year')
      );
    }

    if (currentPlan === 'Professional' && currentInterval === 'month') {
      // Professional monthly: Can upgrade to all plans monthly and yearly
      return (
        (plan === 'Team' && interval === 'month') ||
        (plan === 'Enterprise' && interval === 'month') ||
        (plan === 'Professional' && interval === 'year') ||
        (plan === 'Team' && interval === 'year') ||
        (plan === 'Enterprise' && interval === 'year')
      );
    }

    if (currentPlan === 'Professional' && currentInterval === 'year') {
      // Professional yearly: Can upgrade to Team and Enterprise yearly, but cannot select any monthly plans
      return (
        (plan === 'Team' && interval === 'year') ||
        (plan === 'Enterprise' && interval === 'year')
      );
    }

    if (currentPlan === 'Team' && currentInterval === 'year') {
      // Team yearly: Can only upgrade to Enterprise yearly; no selection allowed for monthly plans or Professional yearly
      return plan === 'Enterprise' && interval === 'year';
    }

    // Default to true if no specific rules apply
    return true;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoader(true);
      const countryData = await getCountry();
      let country = 'India';
      if (countryData) {
        setLoader(false);
        country = countryData?.country || 'India';
      } else {
        setLoader(false);
        country = 'India';
      }
      setUserLocation(country);
      if (country === 'India') {
        setPlanPrices({
          Professional: {
            monthly: 199,
            yearly: 1990,
          },
          Team: {
            monthly: 499,
            yearly: 4990,
          },
          Enterprise: {
            monthly: 999,
            yearly: 9990,
          },
        });
      } else if (country === 'United States') {
        setPlanPrices({
          Professional: {
            monthly: 9,
            yearly: 90,
          },
          Team: {
            monthly: 99,
            yearly: 990,
          },
          Enterprise: {
            monthly: 999,
            yearly: 9990,
          },
        });
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (
      subscriptionDetails &&
      subscriptionDetails.subscription_period == 'year'
    ) {
      setIsIndividual(false);
      setSelectedInterval('yearly');
    }
  }, [subscriptionDetails]);

  const handleIncrease = () => {
    if (selectedPlan == 'Team') {
      setAddUser((prevCount) => (prevCount < 6 ? prevCount + 1 : prevCount)); //maximum 10 users
    } else if (selectedPlan == 'Enterprise') {
      setAddUser((prevCount) => (prevCount < 20 ? prevCount + 1 : prevCount)); //maximum 10 users
    }
  };
  const handleDecrease = () => {
    setAddUser((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount)); //minimum 1 user
  };

  const handleSelectPlan = (plan: string) => {
    setAddUser(1);
    setSelectedPlan(plan === selectedPlan ? null : plan);
  };

  const handleToggle = (plan: string) => {
    if (plan === 'month' && !isIndividual) {
      setIsIndividual(true);
      setSelectedPlan(null);
      setAddUser(1);
      setIsDowngrade(false);
      setSelectedInterval('monthly');
    } else if (plan === 'year' && isIndividual) {
      setIsIndividual(false);
      setSelectedPlan(null);
      setAddUser(1);
      setIsDowngrade(false);
      setSelectedInterval('yearly');
    }
  };

  const handleUpgradePlan = async () => {
    setConfirmVisible(true);
  };

  const handleConfirm = async () => {
    if (isDowngrade) {
      await handleConfirmDowngradePlan();
    } else {
      await handleConfirmUpgradePlan();
    }
  };

  const handleConfirmUpgradePlan = async () => {
    if (selectedPlan) {
      setConfirmVisible(false);
      try {
        setUpgradePlanLoader(true);
        const addUsers = selectedPlan === 'Professional' ? 0 : addUser;
        const response = await upgradePlan({
          selectedPlan,
          addUsers,
          userLocation,
          selectedInterval: selectedInterval,
        });
        if (response?.data.success) {
          setTimeout(() => {
            setUpgradePlanLoader(false);
            navigate('/account-billing');
          }, 3000);
        } else {
          setUpgradePlanLoader(false);
          console.error('Upgrade failed:', response?.data.error); // Handle the failure case
        }
      } catch (error) {
        setUpgradePlanLoader(false);
        console.error('Error upgrading plan:', error); // Log any errors that occur
      }
    }
  };

  const handleConfirmDowngradePlan = async () => {
    if (selectedPlan) {
      setConfirmVisible(false);
      const addUsers = selectedPlan === 'Professional' ? 0 : addUser;

      const dataTosend = {
        selectedPlan: selectedPlan,
        addUsers: addUsers,
        userLocation: userLocation,
        selectedInterval: selectedInterval,
      };

      try {
        setUpgradePlanLoader(true);
        const response = await downgradePlan(dataTosend);
        if (response?.data.success) {
          setTimeout(() => {
            setUpgradePlanLoader(false);
            navigate('/account-billing');
          }, 3000);
        } else {
          setUpgradePlanLoader(false);
          console.error('Downgrade failed:', response?.data.error); // Handle the failure case
        }
      } catch (error) {
        setUpgradePlanLoader(false);
        console.error('Error downgrading plan:', error); // Log any errors that occur
      }
    }
  };

  return (
    <>
      <Header
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        onAboutUsClick={() => null}
        onPricingClick={() => null}
        onContactUsClick={() => null}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          {upgradePlanLoader && <CustomLoader />}
          <div className="p-10 h-screen font-poppins ">
            <div className="mt-8 mb-8">
              <h1 className="text-black text-[30px] font-semibold text-center ">
                Upgrade / Downgrade Plan
              </h1>
            </div>
            <div className="flex flex-row flex-wrap gap-3 justify-center">
              <div className="bg-[#B6B6B6] p-2 rounded-full shadow-lg w-full max-w-[90vw] sm:max-w-[60vw] xl:max-w-[40vw]">
                {/* Toggle Switch */}
                <div className="flex bg-gray-300 rounded-full">
                  {/* Monthly Plans Button */}
                  <button
                    className={`flex items-center justify-center h-10 sm:h-12 w-1/2 rounded-full transition-colors duration-300 ease-in-out ${
                      isIndividual
                        ? 'bg-white text-black shadow-md'
                        : 'bg-gray-300 text-gray-600'
                    }`}
                    onClick={() => handleToggle('month')}
                  >
                    <div className="flex items-center space-x-2 text-[16px] lg:text-[20px]">
                      <span>Monthly</span>
                    </div>
                  </button>

                  {/* Yearly Plans Button */}
                  <button
                    className={`flex items-center justify-center h-10 sm:h-12 w-1/2 rounded-full transition-colors duration-300 ease-in-out ${
                      !isIndividual
                        ? 'bg-white text-black shadow-md'
                        : 'bg-gray-300 text-gray-600'
                    }`}
                    onClick={() => handleToggle('year')}
                  >
                    <div className="flex items-center space-x-2 text-[16px] lg:text-[20px]">
                      <span>Yearly</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            {isIndividual ? (
              <>
                <div className="overflow-auto mt-6">
                  <div className="flex flex-col items-center">
                    <div className="grid gap-5 mt-5 w-[95%] px-4 sm:px-0 sm:grid-cols-2 lg:grid-cols-3">
                      {/* Professional Plan Card */}
                      <div
                        className={`${
                          selectedPlan === 'Professional'
                            ? 'border-3 border-purpletacx p-8 rounded-lg text-purpletacx'
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-full h-auto lg:h-[650px]`}
                      >
                        <p className="font-semibold text-xl sm:text-2xl mb-6 sm:mb-8">
                          Professional
                        </p>
                        <p className="font-light text-sm sm:text-base mb-2">
                          For individuals
                        </p>
                        <p className="font-semibold text-lg sm:text-xl">{`${userLocation === 'India' ? `₹` : `$`}${planPrices.Professional.monthly}/month`}</p>
                        {isCurrentPlan('Professional', 'month') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Professional' ? (
                          <button
                            className="bg-purpletacx text-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-semibold mt-6 sm:mt-8 hover:bg-yellowtacx"
                            onClick={handleUpgradePlan}
                          >
                            {isDowngrade ? 'Downgrade plan' : 'Upgrade plan'}
                          </button>
                        ) : canSelectPlan('Professional', 'month') ? (
                          <button
                            className="mt-6 text-purpletacx bg-white py-2 sm:py-3 px-4 sm:px-6 w-full rounded-full text-sm sm:text-md font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Professional');
                              setIsDowngrade(false);
                            }}
                          >
                            Upgrade plan
                          </button>
                        ) : (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Professional');
                              setIsDowngrade(true);
                            }}
                          >
                            Downgrade Plan
                          </button>
                        )}
                        <div className="mt-6 sm:mt-12 flex flex-col gap-2 sm:gap-4 text-sm sm:text-base">
                          <p className="font-medium">Includes:</p>
                          <p className="flex items-center">
                            <span>✓</span> Advanced card designer
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Free TACX smart card
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Analytics
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Email support
                          </p>
                        </div>
                      </div>

                      {/* Team Plan Card */}
                      <div
                        className={`${
                          selectedPlan === 'Team'
                            ? 'border-3 border-purpletacx p-8 rounded-lg text-purpletacx'
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-full h-auto lg:h-[650px]`}
                      >
                        <p className="font-semibold text-xl sm:text-2xl mb-6 sm:mb-8">
                          Team
                        </p>
                        <p className="font-light text-sm sm:text-base mb-2">
                          1 user included!{' '}
                          <span>(₹10 for each extra user)</span>
                        </p>
                        <p className="font-semibold text-lg sm:text-xl">{`${userLocation === 'India' ? `₹` : `$`}${planPrices.Team.monthly}/month`}</p>
                        {isCurrentPlan('Team', 'month') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Team' ? (
                          <>
                            <hr />
                            <div className="p-2 sm:p-3">
                              <p className="text-xs sm:text-sm">Add users</p>
                              <div className="flex flex-row justify-between w-16 sm:w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                <button
                                  className="bg-white rounded-full h-4 sm:h-5 w-4 sm:w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleDecrease}
                                >
                                  -
                                </button>
                                <input
                                  value={addUser}
                                  className="h-4 sm:h-5 w-4 sm:w-5 bg-transparent border-none text-center items-center flex justify-center outline-none"
                                  readOnly
                                />
                                <button
                                  className="bg-white rounded-full h-4 sm:h-5 w-4 sm:w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleIncrease}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />
                            <button
                              className="bg-purpletacx text-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-semibold mt-6 sm:mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              {isDowngrade ? 'Downgrade plan' : 'Upgrade plan'}
                            </button>
                          </>
                        ) : canSelectPlan('Team', 'month') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Team');
                              setIsDowngrade(false);
                            }}
                          >
                            Upgrade Plan
                          </button>
                        ) : (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Team');
                              setIsDowngrade(true);
                            }}
                          >
                            Downgrade Plan
                          </button>
                        )}
                        <div className="mt-6 sm:mt-12 flex flex-col gap-2 sm:gap-4 text-sm sm:text-base">
                          <p className="font-medium">
                            Everything in "Professional" plus:
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Organize users by team
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> User content control
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> User access levels
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Team analytics
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Priority support
                          </p>
                        </div>
                      </div>

                      {/* Enterprise Plan Card */}
                      <div
                        className={`${
                          selectedPlan === 'Enterprise'
                            ? 'border-3 border-purpletacx p-8 rounded-lg text-purpletacx'
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-full h-auto lg:h-[650px]`}
                      >
                        <p className="font-semibold text-xl sm:text-2xl mb-6 sm:mb-8">
                          Enterprise
                        </p>
                        <p className="font-light text-sm sm:text-base mb-2">
                          20+ Users <span>(₹10 for each extra user)</span>
                        </p>
                        <p className="font-semibold text-lg sm:text-xl">{`${userLocation === 'India' ? `₹` : `$`}${planPrices.Enterprise.monthly}/month`}</p>
                        {isCurrentPlan('Enterprise', 'month') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Enterprise' ? (
                          <>
                            <hr />
                            <div className="p-3">
                              <p className="text-xs sm:text-sm">Add users</p>
                              <div className="flex flex-row justify-between w-16 sm:w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                <button
                                  className="bg-white rounded-full h-4 sm:h-5 w-4 sm:w-5 text-purpletacx flex justify-center items-center"
                                  onClick={handleDecrease}
                                >
                                  -
                                </button>
                                <input
                                  value={addUser}
                                  className="h-4 sm:h-5 w-4 sm:w-5 bg-transparent border-none text-center items-center flex justify-center outline-none"
                                  readOnly
                                />
                                <button
                                  className="bg-white rounded-full h-4 sm:h-5 w-4 sm:w-5 text-purpletacx flex justify-center items-center"
                                  onClick={handleIncrease}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />
                            <button
                              className="bg-purpletacx text-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-semibold mt-6 sm:mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              {isDowngrade ? 'Downgrade plan' : 'Upgrade plan'}
                            </button>
                          </>
                        ) : canSelectPlan('Enterprise', 'month') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Enterprise');
                              setIsDowngrade(false);
                            }}
                          >
                            Upgrade plan
                          </button>
                        ) : (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Enterprise');
                              setIsDowngrade(true);
                            }}
                          >
                            Downgrade Plan
                          </button>
                        )}
                        <div className="mt-6 sm:mt-12 flex flex-col gap-2 sm:gap-4 text-sm sm:text-base">
                          <p className="font-medium">
                            Everything in "Team" plus:
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Dedicated account manager
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Full concierge service available
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="overflow-auto mt-6">
                  <div className="flex flex-col items-center">
                    <div className="grid gap-5 mt-5 w-[95%] px-4 sm:px-0 sm:grid-cols-2 lg:grid-cols-3">
                      {/* Professional Plan Card */}
                      <div
                        className={`${
                          selectedPlan === 'Professional'
                            ? 'border-3 border-purpletacx p-8 rounded-lg text-purpletacx'
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-full h-auto lg:h-[650px]`}
                      >
                        <p className="font-semibold text-xl sm:text-2xl mb-6 sm:mb-8">
                          Professional (year)
                        </p>
                        <p className="font-light text-sm sm:text-base mb-2">
                          For individuals
                        </p>
                        <p className="font-semibold text-base sm:text-xl">
                          {`${userLocation === 'India' ? `₹` : `$`}${planPrices.Professional.yearly}/year`}
                        </p>
                        {isCurrentPlan('Professional', 'year') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Professional' ? (
                          <button
                            className="bg-purpletacx text-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-semibold mt-6 sm:mt-8 hover:bg-yellowtacx"
                            onClick={handleUpgradePlan}
                          >
                            {isDowngrade ? 'Downgrade plan' : 'Upgrade plan'}
                          </button>
                        ) : canSelectPlan('Professional', 'year') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Professional');
                              setIsDowngrade(false);
                            }}
                          >
                            Upgrade Plan
                          </button>
                        ) : (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Professional');
                              setIsDowngrade(true);
                            }}
                          >
                            Downgrade Plan
                          </button>
                        )}
                        <div className="mt-6 sm:mt-12 flex flex-col gap-2 sm:gap-4 text-sm sm:text-base">
                          <p className="font-medium">Includes:</p>
                          <p className="flex items-center">
                            <span>✓</span> Advanced card designer
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Free TACX smart card
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Analytics
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Email support
                          </p>
                        </div>
                      </div>

                      {/* Team Plan Card */}
                      <div
                        className={`${
                          selectedPlan === 'Team'
                            ? 'border-3 border-purpletacx p-8 rounded-lg text-purpletacx'
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-full h-auto lg:h-[650px]`}
                      >
                        <p className="font-semibold text-xl sm:text-2xl mb-6 sm:mb-8">
                          Team (year)
                        </p>
                        <p className="font-light text-sm sm:text-base mb-2">
                          1 user included!{' '}
                          <span>(₹10 for each extra user)</span>
                        </p>
                        <p className="font-semibold text-lg sm:text-xl">
                          {`${userLocation === 'India' ? `₹` : `$`}${planPrices.Team.yearly}/year`}
                        </p>
                        {isCurrentPlan('Team', 'year') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Team' ? (
                          <>
                            <hr />
                            <div className="p-3">
                              <p className="text-sm">Add users</p>
                              <div className="flex flex-row justify-between w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx flex justify-center items-center text-xs sm:text-sm"
                                  onClick={handleDecrease}
                                >
                                  -
                                </button>
                                <input
                                  value={addUser}
                                  className="h-5 w-5 bg-transparent border-none text-center text-xs sm:text-sm outline-none"
                                  readOnly
                                />
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx flex justify-center items-center text-xs sm:text-sm"
                                  onClick={handleIncrease}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />
                            <button
                              className="bg-purpletacx text-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-semibold mt-6 sm:mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              {isDowngrade ? 'Downgrade plan' : 'Upgrade plan'}
                            </button>
                          </>
                        ) : canSelectPlan('Team', 'year') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Team');
                              setIsDowngrade(false);
                            }}
                          >
                            Upgrade plan
                          </button>
                        ) : (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Team');
                              setIsDowngrade(true);
                            }}
                          >
                            Downgrade Plan
                          </button>
                        )}
                        <div className="mt-4 sm:mt-8 flex flex-col gap-2 sm:gap-3 text-sm sm:text-base">
                          <p className="font-medium">
                            Everything in "Professional" plus:
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Organize users by team
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> User content control
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> User access levels
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Team analytics
                          </p>
                        </div>
                      </div>

                      {/* Enterprise Plan Card */}
                      <div
                        className={`${
                          selectedPlan === 'Enterprise'
                            ? 'border-3 border-purpletacx p-8 rounded-lg text-purpletacx'
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-full h-auto lg:h-[650px]`}
                      >
                        <p className="font-semibold text-xl sm:text-2xl mb-6 sm:mb-8">
                          Enterprise (year)
                        </p>
                        <p className="font-light text-sm sm:text-base mb-2">
                          20+ Users <span>(₹10 for each extra user)</span>
                        </p>
                        <p className="font-semibold text-lg sm:text-xl">
                          {`${userLocation === 'India' ? `₹` : `$`}${planPrices.Enterprise.yearly}/year`}
                        </p>
                        {isCurrentPlan('Enterprise', 'year') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Enterprise' ? (
                          <>
                            <hr />
                            <div className="p-3">
                              <p className="text-sm">Add users</p>
                              <div className="flex flex-row justify-between w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleDecrease}
                                >
                                  -
                                </button>
                                <input
                                  value={addUser}
                                  className="h-5 w-5 bg-transparent border-none text-center items-center flex justify-center outline-none"
                                  readOnly
                                />
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleIncrease}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />

                            <button
                              className="bg-purpletacx text-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-semibold mt-6 sm:mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              {isDowngrade ? 'Downgrade plan' : 'Upgrade plan'}
                            </button>
                          </>
                        ) : canSelectPlan('Enterprise', 'year') ? (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Enterprise');
                              setIsDowngrade(false);
                            }}
                          >
                            Upgrade plan
                          </button>
                        ) : (
                          <button
                            className="mt-6 sm:mt-8 text-purpletacx bg-white py-2 sm:py-3 w-full rounded-full text-sm sm:text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => {
                              handleSelectPlan('Enterprise');
                              setIsDowngrade(true);
                            }}
                          >
                            Downgrade Plan
                          </button>
                        )}
                        <div className="mt-6 sm:mt-12 flex flex-col gap-2 sm:gap-4 text-sm sm:text-base">
                          <p className="font-medium">
                            Everything in "Team" plus:
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Dedicated account manager
                          </p>
                          <p className="flex items-center">
                            <span>✓</span> Full concierge service available
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              ref={comparisonSectionRef}
              className="flex flex-col justify-center mt-15 content-center items-center pb-10 md:px-8"
            >
              <h1 className="text-black text-xl xl:text-3xl font-bold text-center">
                Plan Comparison
              </h1>

              <div className="overflow-x-auto w-full mt-6 border">
                <table className="min-w-full divide-y divide-gray-200 rounded-xl sm:w-full">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="p-8 text-left text-md font-semibold text-black tracking-wider"
                      >
                        Feature
                      </th>
                      <th
                        scope="col"
                        className="px-3 text-left text-md font-semibold text-black tracking-wider"
                      >
                        Free
                      </th>
                      <th
                        scope="col"
                        className="px-3 text-left text-md font-semibold text-black  tracking-wider"
                      >
                        Professional
                      </th>
                      <th
                        scope="col"
                        className="px-3 text-left text-md font-semibold text-black tracking-wider"
                      >
                        Team
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                        CARD DESIGNER
                      </td>
                    </tr>

                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 flex items-center gap-5 whitespace-nowrap text-sm text-black font-semibold">
                        Design Elements
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Basic
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Advanced
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Advanced
                      </td>
                    </tr>

                    <tr>
                      <td className="p-3 px-6 flex items-center gap-5 whitespace-nowrap text-sm text-black font-semibold">
                        Card Styling
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Basic
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Advanced
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Advanced
                      </td>
                    </tr>

                    <tr className="bg-[#5942551A] text-black">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Social Profile Hub
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Up to 4 social links
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>

                    <tr>
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Create Templates
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>

                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Call to actions
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Testimonial Wall
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Photo Gallery
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Youtube/Vimeo videos*
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Up to 5
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Up to 6 per user
                      </td>
                    </tr>

                    <tr>
                      <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                        COMPANY & USER PROFILES
                      </td>
                    </tr>

                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Team member management
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Organize users by team
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Up to 6 team
                      </td>
                    </tr>
                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        User content control
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Custom fields
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Link your CRM form
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Link your booking software
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>
                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Custom links
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>

                    <tr>
                      <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                        ANALYTICS
                      </td>
                    </tr>

                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Analytics
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="px-3">
                        <MyIcon iconName="checkSmall" />
                      </td>
                    </tr>

                    <tr>
                      <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                        INTEGRATIONS
                      </td>
                    </tr>

                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Third part Testimonials
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Coming soon
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Coming soon
                      </td>
                    </tr>
                    <tr>
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Zapier
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Coming soon
                      </td>
                      <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                        Coming soon
                      </td>
                    </tr>

                    <tr>
                      <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                        SUPPORT
                      </td>
                    </tr>

                    <tr className="bg-[#5942551A]">
                      <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                        Email
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="uncheckSmall" />
                      </td>
                      <td className="px-3">
                        {' '}
                        <MyIcon iconName="checkSmall" />
                      </td>
                      <td className="flex gap-2 mt-3 px-3 whitespace-nowrap text-sm text-black font-medium">
                        <MyIcon iconName="checkSmall" />
                        <span>Priority</span>
                      </td>
                    </tr>
                    <tr className="h-20">
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {confirmVisible && (
              <>
                <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
                  <div className="bg-[#ffff] flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                    <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                      {/* <div className="flex flex-col gap-3 w-full text-black">
                        <div className="flex justify-between">
                          <div>
                            <p className="text-lg font-semibold">
                              {selectedPlan}
                            </p>
                          </div>
                          <div>
                            {selectedPlan && (
                              <p className="font-semibold text-lg">{`${userLocation == 'IN' ? `₹` : `$`}${planPrices[selectedPlan][selectedInterval]}`}</p>
                            )}
                          </div>
                        </div>
                        {addUser > 1 && (
                          <div className="flex justify-between">
                            <div>
                              <p className="font-semibold text-lg">
                                Additional Users
                              </p>
                              <p className="text-sm">Qty {addUser - 1}</p>
                            </div>
                            <div>
                              <p className="text-end font-semibold text-lg">
                                {`${userLocation == 'India' ? `₹` : `$`}${(addUser - 1) * (selectedInterval === 'monthly' ? 10 : 20)}`}
                              </p>
                              <p className="text-sm">
                                {' '}
                                {selectedInterval == 'monthly'
                                  ? `${userLocation == 'India' ? `₹` : `$`}10.00 each`
                                  : `${userLocation == 'India' ? `₹` : `$`}20.00 each`}
                              </p>
                            </div>
                          </div>
                        )}
                        <hr />
                        <div className="flex justify-between">
                          <div>
                            <p>
                              <span className="font-semibold text-lg">
                                Total
                              </span>{' '}
                              {selectedInterval == 'monthly' ? (
                                <span className="text-sm">
                                  (Billed monthly)
                                </span>
                              ) : (
                                <span className="text-sm">(Billed yearly)</span>
                              )}
                            </p>
                          </div>
                          <div>
                            {selectedPlan && (
                              <p className="font-semibold text-lg">{`${userLocation == 'India' ? `₹` : `$`}${planPrices[selectedPlan][selectedInterval] + (addUser - 1) * (selectedInterval === 'monthly' ? 10 : 20)}`}</p>
                            )}
                          </div>
                        </div>
                      </div> */}
                      <div className="mb-5">
                        {isDowngrade ? (
                          <MyIcon iconName="downgrade" />
                        ) : (
                          <GrUpgrade
                            style={{
                              fontSize: '60px',
                              color: '#594255',
                            }}
                          />
                        )}
                      </div>
                      <p className="text-xl text-purpletacx font-bold">
                        {isDowngrade ? 'Downgrade' : 'Upgrade'} Confirmation
                      </p>
                      <p className="mt-2">
                        Are you sure you want to{' '}
                        {isDowngrade ? 'downgrade' : 'upgrade'} to this plan?
                      </p>
                      <div className="mt-5 w-full flex gap-2 justify-between">
                        <button
                          className="flex justify-center py-2 w-full border border-purpletacx text-black font-semibold rounded-full"
                          onClick={() => {
                            setConfirmVisible(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="flex justify-center py-2 w-full bg-[#DFC749] text-black font-semibold rounded-full"
                          onClick={handleConfirm}
                        >
                          Confirm
                        </button>
                      </div>

                      <p className="mt-3 text-sm">
                        <span className="font-semibold">Note:</span> By
                        confirming, you agree to{' '}
                        {isDowngrade ? 'downgrade' : 'upgrade'} your plan, and
                        the payment will be processed, deducting the amount from
                        your account.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UpgradePlan;
