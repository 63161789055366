import React, { useState } from 'react';
import { sendMailDataToRplyContact } from '../../api/Adminapi';
import CustomLoader from '../../common/loader/Customloader';


interface MailModalProps {
  isOpen: boolean;
  onClose: () => void;
  emailData: {
    email: string;
    first_name?: string;
    last_name?: string;
    subject?: string;
    message?: string;
  };
}

const MailModal: React.FC<MailModalProps> = ({ isOpen, onClose, emailData }) => {
  console.log(emailData);
  const { email, first_name, last_name, message: userMessage } = emailData;

  const [message, setMessage] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const sendMailData = {
      fullName: `${first_name ?? ''} ${last_name ?? ''}`.trim(),
      email,
      subject: subject,
      message,
      userMessage: userMessage ?? '',
    };

    console.log(sendMailData,"sendMail");
    try {
      setLoading(true)
      const response = await sendMailDataToRplyContact(sendMailData);
      console.log('Mail sent successfully:', response);
      setMessage('')
      setSubject('')
      onClose();

    } catch (error) {
      console.error('Error sending mail:', error);
      alert('Error sending mail');
    }finally{
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  if(loading) return <CustomLoader/>

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Send Mail</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">&times;</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">To:</label>
            <input
              type="email"
              id="email"
              value={email}
              className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-purple-500 focus:outline-none"
              readOnly
            />
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">Subject:</label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-purple-500 focus:outline-none"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-purple-500 focus:outline-none"
              rows={4}
              required
            />
          </div>
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-purpletacx text-white px-4 py-2 rounded-lg hover:bg-purpletacx transition duration-200"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>


  );
};

export default MailModal;
