import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../../layout/Defaultlayout';
import { GetUserDetails } from '../../api/Adminapi';
import Loader from '../../common/loader/Index';
import { getIndustryList } from '../../api/Api';
import Select, { StylesConfig } from 'react-select';
import MyIcon from '../cardeditor/icons/Myicon';
const URL = import.meta.env.VITE_API_URL;

interface StateOrCountry {
  label: string;
}

interface UserDetails {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  user_role: string;
  about_business: string;
  address: string;
  city: string;
  company_name: string;
  country: string | StateOrCountry;
  created_at: any;
  industry: string;
  job_title: string;
  office_number: string;
  phone_number: string;
  postal_code: string;
  profile_picture: string;
  state: string | StateOrCountry;
  teamSize: string;
  website: string;
  timeOff?: {
    type: string;
    startDate: string;
    endDate: string;
  }[];
}

const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    // padding: '0 10px',
    // borderColor: '#D8D8D8',
    border: 'none',
    background: '#EFEDEE',
    padding: '2px',
    boxShadow: 'none',
    fontSize: '16px',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: '#DFC749',
      color: '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

// type SelectedComponentKey = 'userInfo' | 'profile' | 'address' | 'businessInfo';
interface ToggleProps {
  [key: string]: boolean;
}

const UserDetail: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  // const [selectedComponent, setSelectedComponent] = useState({
  //   userInfo: false,
  //   profile: false,
  //   address: false,
  //   businessInfo: false,
  // });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [industryOption, setIndustryOption] = useState<
    { value: string; label: string }[]
  >([{ value: '', label: 'Select' }]);

  const decodeToken = (encodedId: string): string => {
    return atob(encodedId);
  };

  useEffect(() => {
    fetchUserDetail();
  }, [userId]);

  const fetchUserDetail = async (): Promise<void> => {
    if (userId) {
      const decodedUserId = decodeToken(userId);
      setLoading(true);
      try {
        const response = await GetUserDetails(decodedUserId);
        setUserDetails(response?.data.user);
      } catch (error) {
        console.error('Error fetching user detail:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchAllIndustryList = async () => {
      const List = await getIndustryList();
      if (List?.data.content) {
        const industryList = List?.data.content;
        const formattedOptions = industryList.map((industry: any) => ({
          value: industry.id,
          label: industry.label,
        }));
        setIndustryOption([{ value: '', label: 'N/A' }, ...formattedOptions]);
      }
    };
    fetchAllIndustryList();
  }, []);

  // const handleToggleAccordion = (component: SelectedComponentKey) => {
  //   setSelectedComponent((prev) => ({
  //     ...prev,
  //     [component]: !prev[component],
  //   }));
  // };

  // image zoom support
  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const [selectedComponents, setSelectedComponents] = useState('profile');

  // const handleToggleAccordions = (component: any) => {
  //   if (selectedComponents !== component) {
  //     setSelectedComponents(component);
  //   }
  // };
  const [selectedComponents, setSelectedComponents] = useState<ToggleProps>({
    profile: true,
    businessInfo: true,
  });

  const handleToggleAccordion = (component: string) => {
    setSelectedComponents((pre) => ({
      ...pre,
      [component]: !pre[component],
    }));
  };

  const customFilter = (option: any, inputValue: string): boolean => {
    return option?.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
  };

  return (
    <DefaultLayout title="User Information">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="max-w-full mx-auto">
            <div className="flex justify-end mb-4">
              <button
                className={`bg-purpletacx text-[#000] rounded-full px-3 py-3 transition-transform duration-300 `}
                onClick={() => {
                  navigate('/admin/dashboard');
                }}
              >
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.83227 5.22791H1.31073C1.05147 5.22786 0.79806 5.15094 0.582517 5.00688C0.366975 4.86282 0.198983 4.65809 0.0997794 4.41857C0.000575467 4.17905 -0.0253864 3.91549 0.0251759 3.66122C0.0757382 3.40695 0.200554 3.17337 0.383845 2.99003L3.00586 0.368015C3.25312 0.129204 3.58428 -0.00293746 3.92802 4.95595e-05C4.27176 0.00303658 4.60058 0.140914 4.84365 0.383985C5.08672 0.627056 5.2246 0.955872 5.22759 1.29961C5.23057 1.64336 5.09843 1.97452 4.85962 2.22178L4.47681 2.6059H9.83227C10.18 2.6059 10.5134 2.74403 10.7593 2.98989C11.0051 3.23575 11.1433 3.56921 11.1433 3.91691C11.1433 4.26461 11.0051 4.59807 10.7593 4.84393C10.5134 5.08979 10.18 5.22791 9.83227 5.22791Z"
                    fill="#DFC749"
                  />
                  <path
                    d="M12.4546 14.4053H1.31101C0.963306 14.4053 0.629846 14.2672 0.383985 14.0213C0.138123 13.7754 0 13.442 0 13.0943C0 12.7466 0.138123 12.4131 0.383985 12.1673C0.629846 11.9214 0.963306 11.7833 1.31101 11.7833H12.4546C13.2423 11.7833 14.0037 11.4997 14.5994 10.9843C15.1951 10.4689 15.5853 9.75626 15.6987 8.97673C15.812 8.19721 15.6408 7.40294 15.2165 6.73927C14.7922 6.0756 14.1431 5.58691 13.388 5.36263C13.0548 5.26363 12.7746 5.03639 12.6089 4.73085C12.4433 4.42532 12.4057 4.0665 12.5045 3.73328C12.6033 3.40005 12.8304 3.11969 13.1358 2.95383C13.4412 2.78797 13.8 2.75018 14.1333 2.84877C15.4926 3.25218 16.6611 4.13159 17.4251 5.32605C18.1891 6.52051 18.4973 7.95014 18.2935 9.35329C18.0897 10.7564 17.3874 12.0393 16.3151 12.967C15.2429 13.8947 13.8724 14.4053 12.4546 14.4053Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div
              id="officeNumber"
              className="bg-white shadow-md w-full rounded-lg"
            >
              <div
                className="px-6 py-4 border-b border-[#EDEDED] cursor-pointer flex justify-between items-center"
                onClick={() => {
                  handleToggleAccordion('profile');
                }}
              >
                <h4
                  id="websiteUrl"
                  className="text-[#594255] font-[600] text-lg md:text-xl"
                >
                  Personal Information
                </h4>
                <span
                  className={`pointer-events-none transition-transform duration-200 ease-in-out ${selectedComponents.profile ? 'rotate-180' : 'rotate-0'}`}
                >
                  <MyIcon iconName="dropdown" />
                </span>
              </div>
              {selectedComponents.profile && (
                <>
                  <div className="p-6 overflow-y-auto max-h-500">
                    <div className="flex w-full justify-between flex-wrap">
                      <div className="flex flex-col w-full md:basis-[20%] xl:basis-[25%]">
                        <div className="flex py-6 flex-col text-center items-center">
                          <div className="w-32 h-32 rounded-full overflow-hidden border border-stroke mb-3">
                            <img
                              src={`${URL}/public/cards/images/${userDetails?.profile_picture || 'profile.jpg'}`} // Use userDetails.profile_picture or fallback
                              alt="User Profile"
                              className="w-full h-full object-cover cursor-pointer"
                              onClick={handleImageClick}
                            />
                          </div>
                          <div className="flex flex-col justify-center">
                            <p className="text-lg font-bold text-black">{`${userDetails?.first_name} ${userDetails?.last_name}`}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full md:basis-[70%] xl:basis-[75%]">
                        <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              Full Name
                            </label>
                            <input
                              type="text"
                              value={`${userDetails?.first_name} ${userDetails?.last_name}`}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              Email
                            </label>
                            <input
                              type="email"
                              value={userDetails?.email}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              value={userDetails?.phone_number}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              User Role
                            </label>
                            <input
                              type="text"
                              value={userDetails?.user_role}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              Created At
                            </label>
                            <input
                              type="text"
                              value={`${new Date(userDetails?.created_at)
                                .toLocaleDateString('en-GB', {
                                  year: 'numeric',
                                  day: '2-digit',
                                  month: 'short',
                                })
                                .toUpperCase()} at ${new Date(
                                userDetails?.created_at,
                              ).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true, // Change to false if you want 24-hour format
                              })}`}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              Address
                            </label>
                            <input
                              type="text"
                              value={userDetails?.address}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              City
                            </label>
                            <input
                              type="text"
                              value={userDetails?.city}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              State
                            </label>
                            <input
                              type="text"
                              value={
                                typeof userDetails?.state === 'string'
                                  ? JSON.parse(userDetails.state).label // Parse if it's a string
                                  : userDetails?.state?.label // Access the label if it's already an object
                              }
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>

                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              State
                            </label>
                            <input
                              type="text"
                              value={
                                typeof userDetails?.country === 'string'
                                  ? JSON.parse(userDetails.country).label // Parse if it's a string
                                  : userDetails?.country?.label // Access the label if it's already an object
                              }
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>

                          <div className="flex flex-col">
                            <label className="font-medium text-black">
                              Postal Code
                            </label>
                            <input
                              type="text"
                              value={userDetails?.postal_code}
                              readOnly
                              className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              id="officeNumber"
              className="bg-white shadow-md w-full rounded-lg mt-5"
            >
              <div
                className="px-6 py-4 border-b border-[#EDEDED] cursor-pointer flex justify-between items-center"
                onClick={() => {
                  handleToggleAccordion('businessInfo');
                }}
              >
                <h4
                  id="websiteUrl"
                  className="text-[#594255] font-[600] text-lg md:text-xl"
                >
                  Business Information
                </h4>
                <span
                  className={`pointer-events-none transition-transform duration-200 ease-in-out ${selectedComponents.businessInfo ? 'rotate-180' : 'rotate-0'}`}
                >
                  <MyIcon iconName="dropdown" />
                </span>
              </div>
              {selectedComponents.businessInfo && (
                <>
                  <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <label className="font-medium text-black">
                        Company Name
                      </label>
                      <input
                        type="text"
                        value={userDetails?.company_name || 'N/A'}
                        readOnly
                        className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-medium text-black">
                        Industry
                      </label>
                      <Select
                        options={industryOption}
                        styles={customStyles}
                        className="mt-1"
                        placeholder="Select"
                        value={industryOption.find(
                          (option) => option.value === userDetails?.industry,
                        )}
                        filterOption={customFilter}
                        isDisabled
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-medium text-black">
                        Job Title
                      </label>
                      <input
                        type="text"
                        value={
                          userDetails?.job_title
                            ? JSON.parse(userDetails?.job_title).length > 0
                              ? JSON.parse(userDetails?.job_title).join(', ')
                              : 'No job title'
                            : 'No job title'
                        }
                        readOnly
                        className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                      />
                    </div>

                    <div className="flex flex-col">
                      <label className="font-medium text-black">Website:</label>
                      <input
                        type="text"
                        value={userDetails?.website || 'N/A'}
                        readOnly
                        className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-medium text-black">
                        Office Number
                      </label>
                      <input
                        type="text"
                        value={userDetails?.office_number || 'N/A'}
                        readOnly
                        className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-medium text-black">
                        Team Size
                      </label>
                      <input
                        type="text"
                        value={userDetails?.teamSize}
                        readOnly
                        className="mt-1 rounded-lg w-full bg-[#EFEDEE] py-2 px-4 text-[#000000] outline-none focus-visible:shadow-none"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            {isModalOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                onClick={closeModal}
              >
                <div className="bg-white p-4 rounded-lg">
                  <img
                    src={`${URL}/public/cards/images/${userDetails?.profile_picture || 'profile.jpg'}`}
                    alt="Zoomed User Profile"
                    className="w-96 h-96 object-cover"
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default UserDetail;
