import { useEffect, useState } from 'react';
import DefaultLayout from '../../layout/Defaultlayout';
import MyIcon from '../../components/cardeditor/icons/Myicon';
import {
  getZohoTokenData,
  guideTourComplete,
  updateZohoStatus,
} from '../../api/Api';
import Loader from '../../common/loader/Index';
import { useNavigate } from 'react-router-dom';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useTour } from '../../Authcontext';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/Userslice';

interface zohoDataProps {
  status: number;
}
const Integration = () => {
  const [enabled, setEnabled] = useState(false);
  const api_url = import.meta.env.VITE_API_URL;
  const [zohoData, setZohoData] = useState<zohoDataProps>();
  const [loading, setLoading] = useState(true);
  const [successVisible, setSuccessVisible] = useState(false);
  const [integrateVisible, setIntegrateVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);
  const [statusChangeSuccess, setStatusChangeSuccess] = useState(false);
  const navigate = useNavigate();
  const userDetails = useSelector(selectUser);
  const userData = userDetails?.user;

  useEffect(() => {
    GetZohoData();
    checkUrlForStatus();
  }, []);

  useEffect(() => {
    if (zohoData?.status === 1) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [zohoData?.status]);

  const GetZohoData = async () => {
    try {
      setLoading(true);
      const response = await getZohoTokenData();
      if (response?.data.success) {
        setZohoData(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error getting user zoho data', error);
    }
  };

  const handleZohoLogin = async () => {
    try {
      window.location.href = `${api_url}/zoho/auth`;
      setIntegrateVisible(false);
    } catch (error) {
      console.error('Zoho login failed', error);
      setIntegrateVisible(false);
    }
  };

  const handleToggleChange = async () => {
    const newEnabled = !enabled;
    setEnabled(newEnabled);

    if (newEnabled) {
      // If the toggle is enabled, trigger Zoho login
      setIntegrateVisible(true);
      // handleZohoLogin();
    } else {
      // When the toggle is disabled, update the status to 0
      try {
        const response = await updateZohoStatus({ status: 0 });
        if (response?.data.success) {
          setZohoData((prevData) => ({ ...prevData, status: 0 }));
          setStatusChangeSuccess(true);
          setTimeout(() => {
            setStatusChangeSuccess(false);
          }, 3000);
        } else {
          console.error('Failed to update status');
          setStatusChangeSuccess(false);
        }
      } catch (error) {
        console.error('Error updating status to 0', error);
        setStatusChangeSuccess(false);
      }
    }
  };

  // Function to check the URL for success or error status
  const checkUrlForStatus = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('success')) {
      setSuccessVisible(true);
      setErrorVisible(false);
      setSuccessMessage('Integration successful!');
      // Reset URL after 3 seconds
      setTimeout(() => {
        navigate('/integration', { replace: true });
        setSuccessVisible(false);
      }, 2000);
    }

    if (searchParams.get('error')) {
      setErrorVisible(true);
      setSuccessVisible(true);
      setSuccessMessage('Integration failed: Access denied');
      // Reset URL after 3 seconds
      setTimeout(() => {
        navigate('/integration', { replace: true });
        setErrorVisible(false);
        setSuccessVisible(false);
      }, 2000);
    }
  };

  const handleSuccess = () => {
    setSuccessVisible(false);
    setSuccessMessage('');
  };

  const handleCancelIntegration = () => {
    setIntegrateVisible(false);
    setEnabled(false);
  };

  //  ************* website tour *************
  const { tourStep, setTourStep } = useTour();

  useEffect(() => {
    setTimeout(() => {
      // Check if the tour has already been completed
      if (userData?.tour_guide == 0 && tourStep === 5) {
        startTour();
      }
    }, 300);
  }, [tourStep, zohoData]);

  // Start the tour for Page 1
  const startTour = () => {
    const tour = driver({
      showProgress: true,
      doneBtnText: 'Next →',
      steps: [
        {
          element: '#zoho-integration',
          popover: {
            title: 'Zoho Integration',
            description: '',
            side: 'bottom',
            align: 'start',
          },
        },
      ],
      // Triggered when the last step is deselected, so we can complete the tour
      onDestroyStarted: async (_, options) => {
        if (options.element === '#zoho-integration') {
          onCompleteTour();
        } else {
          await guideTourComplete({ status: '1' });
        }
        tour.destroy();
      },
    });

    tour.drive();
  };

  // Navigate to the next page and update the step
  const onCompleteTour = () => {
    setTourStep(6);
    navigate('/my-activity');
  };

  return (
    <>
      <DefaultLayout title="Integration">
        {loading ? (
          <Loader />
        ) : (
          <>
            {statusChangeSuccess && (
              <>
                <div className="absolute top-22 right-0 flex w-auto border-l-6 border-[#34D399] bg-[#34D399] bg-opacity-[15%] py-2 px-4 sm:pr-20 shadow-md slideInFromTop-animate">
                  <div className="w-full">
                    <h5 className=" text-md font-semibold text-black dark:text-[#34D399] ">
                      Deactivated CRM Successfully
                    </h5>
                  </div>
                </div>
              </>
            )}
            <div className="bg-white shadow-md rounded-lg w-full sm:w-[50%] mt-5">
              <div
                id="zoho-integration"
                className="flex items-center justify-between space-x-4 p-6"
              >
                <div className="flex items-center">
                  <MyIcon iconName="zohoLogo" />
                  <span className="font-semibold text-purpletacx">
                    Zoho CRM Integration
                  </span>
                </div>
                <label
                  htmlFor="toggle3"
                  className="flex cursor-pointer select-none items-center"
                >
                  <div className="relative">
                    <input
                      type="checkbox"
                      id="toggle3"
                      className="sr-only"
                      onChange={handleToggleChange}
                    />
                    <div className="block h-8 w-14 rounded-full bg-purpletacx"></div>
                    <div
                      className={`dot absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                        enabled && '!right-1 !translate-x-full !bg-white'
                      }`}
                    >
                      <span className={`hidden ${enabled && '!block'}`}>
                        <svg
                          className="fill-[#16a34a]"
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                            fill=""
                            stroke=""
                            strokeWidth="0.4"
                          ></path>
                        </svg>
                      </span>
                      <span className={`${enabled && 'hidden'}`}>
                        <svg
                          className="h-4 w-4 stroke-[#ef4444]"
                          fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </>
        )}
        {successVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
              <div className="bg-[#594255] flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                  {errorVisible ? (
                    <MyIcon iconName="crossLarge" />
                  ) : (
                    <MyIcon iconName="success" />
                  )}
                  <div className="mt-8 text-center text-white">
                    <p className="font-extrabold text-2xl">
                      {' '}
                      {errorVisible ? 'Error' : 'Successful'}
                    </p>
                    <p className="text-md">{successMessage}</p>
                  </div>
                  <div
                    className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-[#DFC749] text-black font-bold cursor-pointer"
                    onClick={handleSuccess}
                  >
                    Okay
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {integrateVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
              <div className="bg-[#ffff] flex m-auto justify-center rounded-lg relative w-[90%] md:w-[60%] xl:w-[45%] slideInFromTop-animate">
                <button
                  className="absolute top-3 right-5"
                  onClick={handleCancelIntegration}
                >
                  <MyIcon iconName="bigcross" color="black" />
                </button>
                <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                  <MyIcon iconName="zoho" />
                  <div className="mt-5 text-center text-black">
                    <p className=" text-sm">
                      You are about to integrate your CRM with our platform.
                      This will allow seamless data syncing and enhanced
                      productivity. Once integrated, your contacts and leads
                      will be automatically synchronized.
                    </p>
                    <p className="text-sm mt-2">
                      Please ensure your CRM credentials are ready. The
                      integration process may take a few minutes.
                    </p>
                  </div>
                  <div
                    className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-yellowtacx text-black font-bold cursor-pointer"
                    onClick={handleZohoLogin}
                  >
                    Connect
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DefaultLayout>
    </>
  );
};

export default Integration;
