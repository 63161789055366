import React, { useEffect, useState } from 'react';
import { getTheContextUsData } from '../../../api/Adminapi';
import DefaultLayout from '../../../layout/Defaultlayout';
import { FaSearch } from 'react-icons/fa';
import Pagination from '../../../components/pagination/Pagination';
import MailModal from '../../../components/model/MailModal';

interface ContactMessage {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  message: string;
  uploaded_file_path: string | null;
  created_at: string;
}

const AdminContactUs: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactUsData, setContactUsData] = useState<ContactMessage[]>([]);
  const [filteredData, setFilteredData] = useState<ContactMessage[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10); // Number of items per page
  const [modalData, setModalData] = useState<any>([]);

  useEffect(() => {
    handleGetTheData();
  }, []);

  const handleGetTheData = async () => {
    const getContactUsData = await getTheContextUsData();
    const getData = getContactUsData?.data.user;
    setContactUsData(getData);
    setFilteredData(getData);
    console.log(getContactUsData?.data.user, 'getContactUsData');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();

    setSearchQuery(query);

    const filteredData = contactUsData.filter(
      (item: ContactMessage) =>
        item.first_name.toLowerCase().includes(query) ||
        item.last_name.toLowerCase().includes(query) ||
        item.email.toLowerCase().includes(query) ||
        item.id.toString().includes(query),
    );
    setFilteredData(filteredData);
    setCurrentPage(1); // Reset to the first page after search
  };

  const handleToSendMail = (message: any) => {
    console.log(message);
    setIsModalOpen(true);
    setModalData(message);
  };

  // Pagination logic
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })} `;
    // at ${date.toLocaleTimeString('en-GB', {
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   hour12: true,
    // })}
  };

  return (
    <>
      <DefaultLayout title="Contact Us">
        <div className="bg-white shadow-lg rounded-lg mt-4">
          <div className="flex flex-row justify-between items-center bg-[#F7F7F7] p-4 rounded-lg">
            <p className="font-medium text-md text-black w-full">
              Requests to support
            </p>
            <div className="flex flex-col sm:justify-end sm:flex-row sm:items-center w-full sm:w-[70%] sm:space-x-4">
              {/* Search Bar */}
              <div className="relative w-full xl:w-[50%] sm:w-full">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="p-2 border border-stroke rounded-xl pr-12 w-full bg-white text-gray-800"
                />
                <FaSearch className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500" />
              </div>
            </div>
          </div>

          <div className="overflow-x-auto animate-fade-in scrollbar-custom p-2">
            <table className="min-w-full rounded-lg overflow-hidden">
              <thead>
                <tr className="hover:bg-gray-50 transition duration-200">
                  <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-12 sm:w-16">
                    ID
                  </th>
                  <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-24 sm:w-32">
                    User Name
                  </th>
                  <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-36 sm:w-48">
                    Email
                  </th>
                  <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-28 sm:w-36">
                    Contact No
                  </th>
                  <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-full sm:w-[15%]">
                    Message
                  </th>
                  <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-24 sm:w-28">
                    Date
                  </th>
                  <th className="py-3 px-5 text-left border-b border-gray whitespace-nowrap w-24 sm:w-28">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((message) => (
                    <tr
                      key={message.id}
                      className="hover:bg-gray-50 transition duration-200"
                    >
                      <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
                        {message.id}
                      </td>
                      <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
                        <span className="text-gray-800 font-medium">
                          {message.first_name} {message.last_name}
                        </span>
                      </td>
                      <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
                        {message.email}
                      </td>
                      <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
                        {message.phone_number || 'N/A'}
                      </td>
                      <td className="py-3 px-5 border-b border-gray">
                        {message.message}
                      </td>
                      <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
                        {formatDateTime(message.created_at)}
                      </td>
                      <td className="py-3 px-5 border-b border-gray whitespace-nowrap">
                        <button
                          onClick={() => handleToSendMail(message)}
                          className="bg-purpletacx flex justify-center items-center gap-1 text-white text-sm rounded-lg py-1.5 w-full hover:bg-yellowtacx transition duration-200"
                        >
                          <p>Send mail</p>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="py-4 text-center">
                      No Data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {totalItems > itemsPerPage && (
            <Pagination
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              startIndex={startIndex}
              endIndex={endIndex}
              totalItems={totalItems}
            />
          )}
        </div>
        <MailModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          emailData={modalData}
        />
      </DefaultLayout>
    </>
  );
};

export default AdminContactUs;
