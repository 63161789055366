import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface NoInternetPageProps {
  image: string;
}

const NoInternetPage: React.FC<NoInternetPageProps> = ({ image }) => {
  const navigate = useNavigate();

  const handleRetry = () => {
    if (navigator.onLine) {
      const lastVisited = localStorage.getItem('lastVisitedUrl');
      if (lastVisited) {
        navigate(lastVisited); // Redirect to the last visited page
        localStorage.removeItem('lastVisitedUrl'); // Clear stored URL
        toast.success('Connection restored! Redirecting...');
      } else {
        navigate('/'); // Default to home page if no URL is stored
        toast.success('Connection restored! Redirecting to home...');
      }
    } else {
      toast.error('Still offline! Please check your connection.');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen text-center bg-white text-gray-800 font-sans p-6 relative overflow-hidden">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="absolute top-10 left-10 w-64 h-64 bg-yellowtacx rounded-full opacity-20 "></div>
      <div className="absolute bottom-10 right-10 w-56 h-56 bg-purpletacx rounded-full opacity-25 "></div>

      {/* Main content */}
      <div className="flex flex-col items-center relative z-10 space-y-6">

        {/* Heading with a unique font style */}
        <h1 className="text-5xl font-extrabold text-gray-900 mb-4 text-shadow-md animate__animated animate__fadeIn animate__delay-1s">
          Oops! No Connection
        </h1>

        {/* Description */}
        <p className="text-lg text-gray-600 max-w-md mb-8 leading-relaxed animate__animated animate__fadeIn animate__delay-2s">
          It seems you've lost your connection. Please check your Wi-Fi or mobile data and try again.
        </p>

        {/* Retry Button with smooth scaling effect */}
        <button
          onClick={handleRetry}
          className="bg-purpletacx text-white py-3 px-10 text-lg rounded-full shadow-xl transform transition-all duration-300 ease-in-out hover:scale-110 hover:shadow-2xl hover:bg-yellowtacx active:scale-95 active:bg-yellowtacx focus:outline-none focus:ring-4 focus:ring-yellowtacx"
        >
          Retry
        </button>
      </div>

      {/* Glowing effect for a sense of importance */}
      <div className="absolute top-1/2 left-1/2 w-80 h-80 bg-gradient-to-r from-yellowtacx via-yellowtacx to-purpletacx rounded-full opacity-10"></div>
    </div>
  );
};

export default NoInternetPage;
