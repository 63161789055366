import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/Defaultlayout';
import { GetAllUsers } from '../../../api/Adminapi';
import { FaSearch } from 'react-icons/fa';
import Pagination from '../../../components/pagination/Pagination';
import Loader from '../../../common/loader/Index';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  company_name?: string;
  profile_picture?: string;
  phone_number?: string;
  plan_name?: string;
  card_type: string;
  status: string;
  card_id: number;
}

const AdminClientdashboard: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const card_url = import.meta.env.VITE_FRONTEND_URL;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await GetAllUsers();
        // Adjusting to access the user array correctly
        if (
          res &&
          res.data &&
          res.data.success &&
          Array.isArray(res.data.user)
        ) {
          setUsers(res.data.user); // Set users to the array from the response
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterUsers = users.filter((user) => {
      const matchesSearchQuery =
        `${user.first_name} ${user.last_name} ${user.email} ${user.company_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      // const matchesStatusFilter = statusFilter
      //   ? user.status === statusFilter
      //   : true;
      return matchesSearchQuery;
    });
    setFilteredUsers(filterUsers);
  }, [searchQuery, users]);

  /* Pagination start */
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Adjust this number as needed

  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

  const secretKey = 2.5;
  const multiplier = 39;
  const offset = 5;
  const encodeIds = (id: any) => {
    return (id * multiplier + offset) ^ secretKey;
  };

  return (
    <DefaultLayout title="Client Dashboard">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bg-white shadow-lg rounded-lg">
            <div className="flex flex-row justify-between items-center mb-4 mt-2 bg-[#F7F7F7] p-4 rounded-lg gap-5">
              <div className="bg-gray-200 rounded-lg w-full">
                <p className="font-medium text-md text-black animate-fade-in text-sm sm:text-base">
                  Showing {Math.min(endIndex, totalItems)} of {totalItems}{' '}
                  clients
                </p>
              </div>
              <div className="flex flex-col sm:justify-end sm:flex-row sm:items-center w-full sm:w-[70%] sm:space-x-4">
                {/* Search Bar */}
                <div className="relative w-full xl:w-[50%] sm:w-full">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="p-2 border border-stroke rounded-xl pr-12 w-full bg-white text-gray-800"
                  />
                  <FaSearch className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500" />
                </div>
              </div>
            </div>

            <div className="overflow-x-auto animate-fade-in scrollbar-custom rounded-lg shadow-md">
              <table className="min-w-full  rounded-lg ">
                <thead>
                  <tr className="hover:bg-gray-50 transition duration-200">
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      NO
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      Client Name
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left border-b border-gray whitespace-nowrap"
                    >
                      Company Name
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-center border-b border-gray whitespace-nowrap "
                    >
                      Card URL
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedUsers?.length > 0 ? (
                    paginatedUsers?.map((user, index) => {
                      const encoded = user.id ? encodeIds(user.card_id) : null;
                      return (
                        <>
                          <tr
                            key={user.id}
                            className="hover:bg-gray-50 transition duration-200"
                          >
                            <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                              {index + 1}
                            </td>
                            <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                              {user.first_name} {user.last_name}
                            </td>
                            <td className="py-3 px-6 border-b  border-gray whitespace-nowrap">
                              {user.email}
                            </td>
                            <td
                              className={`py-3 px-6 border-b border-gray whitespace-nowrap`}
                            >
                              {user.company_name || 'N/A'}
                            </td>
                            <td
                              className={`flex justify-center py-3 px-6 border-b border-gray  whitespace-nowrap`}
                            >
                              {user?.plan_name == null ||
                              user?.status == 'ended' ||
                              user?.card_id == null
                                ? 'N/A'
                                : `${card_url}/${user.card_type}-${encoded}`}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="py-4 text-center">
                        No client found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {users?.length > 4 && (
                <div className="mt-4">
                  <Pagination
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalItems={totalItems}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default AdminClientdashboard;
