import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface InternetStatusContextType {
  isOnline: boolean;
}

const InternetStatusContext = createContext<InternetStatusContextType | undefined>(undefined);

interface InternetStatusProviderProps {
  children: ReactNode;
}

export const InternetStatusProvider: React.FC<InternetStatusProviderProps> = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);

      // Navigate back to the previous page if applicable
      const lastVisited = localStorage.getItem('lastVisitedUrl');
      if (lastVisited) {
        navigate(lastVisited);
        localStorage.removeItem('lastVisitedUrl');
      }
    };

    const handleOffline = () => {
      setIsOnline(false);

      // Save the current path and navigate to the NoInternetPage
      const currentPath = window.location.pathname;
      if (currentPath !== '/no-internet') {
        localStorage.setItem('lastVisitedUrl', currentPath);
        navigate('/no-internet');
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [navigate]);

  return (
    <InternetStatusContext.Provider value={{ isOnline }}>
      {children}
    </InternetStatusContext.Provider>
  );
};

// Custom Hook to use the InternetStatusContext
export const useInternetStatus = (): InternetStatusContextType => {
  const context = useContext(InternetStatusContext);
  if (!context) {
    throw new Error('useInternetStatus must be used within an InternetStatusProvider');
  }
  return context;
};
